// ** MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { dialogPaperStyle, dialogStyle, getFormattedDate, timeRegexValidation } from "../utils"

export const DeletePendingChangeModal = ({ shouldOpen, handleConfirmDelete, isPendingGroup, reference }) => {
  const isPendingGroupMessage =
    "This is a pending group change, meaning there are multiple records that have the same program, property, previous and new values. Removing " +
    "this group change will also remove all the associated pending records. Are you sure you'd like to delete this pending group change?"

  const showDeletePendingChangeText = () => {
    // Remove the time from date fields.
    if (reference.previousValue && timeRegexValidation(reference.previousValue)) reference.previousValue = getFormattedDate(reference.previousValue)
    if (reference.newValue && timeRegexValidation(reference.newValue)) reference.newValue = getFormattedDate(reference.newValue)

    return (
      <>
        <p>Are you sure you want to delete this pending change?</p>
        <p>
          <b>Previous Value:</b> <span className="highlight-blue">{!reference.previousValue ? "[Empty]" : reference.previousValue}</span>
        </p>
        <p>
          <ArrowDownwardIcon />
        </p>
        <p>
          <b>New Value:</b> <span className="highlight-blue">{!reference.newValue ? "[Empty]" : reference.newValue}</span>
        </p>
      </>
    )
  }

  const showDenyDeleteArchiveText = () => {
    return (
      <>
        <p>Are you sure you want to delete this pending action?</p>
        <p>
          <b>Action: </b> <span className="highlight-red">{reference.pendingAction}</span>
        </p>
        <p>
          <b>Nomenclature: </b>
          {reference.nomenclature}
        </p>
        <p>
          <b>Property Number: </b>
          {reference.propertyNumber}
        </p>
      </>
    )
  }

  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="delete-pending-change-dialog-title"
        fontFamily="Merriweather"
      >
        Confirm Delete
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleConfirmDelete(false)}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography>
          {isPendingGroup ? isPendingGroupMessage : reference.pendingAction === "EDIT" ? showDeletePendingChangeText() : showDenyDeleteArchiveText()}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleConfirmDelete(true)}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// ** MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { dialogPaperStyle, dialogStyle } from "../utils"

export const NextSignatureModal = ({ shouldOpen, handleConfirmSignature }) => {
  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="next-signature-dialog-title"
        fontFamily="Merriweather"
      >
        Confirm Signature
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleConfirmSignature(false)}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography>
          By signing, you're confirming all necessary tasks have been completed and you agree your <b>username</b> will be applied for the{" "}
          <b>electronic signature</b>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleConfirmSignature(true)}
        >
          Sign
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// ** MUI
import { alpha, styled } from "@mui/material/styles"
import { DataGridPremium, GridToolbar, gridClasses, useGridApiRef } from "@mui/x-data-grid-premium"
import { useCallback, useLayoutEffect, useState } from "react"
import { CustomToolbar } from "./CustomToolbar"
import { useLocation } from "react-router-dom"
import { CircularProgress } from "@mui/material"

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.hover, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
  [`& .dimensions`]: {
    borderLeft: "2px solid #c2630a",
    backgroundColor: "rgba(194, 99, 10, 0.1) !important",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(194, 99, 10, 0.2) !important",
    },
  },
  [`& .pending`]: {
    borderLeft: "2px solid #e1ca23",
    backgroundColor: "rgb(225, 202, 35, 0.1) !important",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(225, 202, 35, 0.2) !important",
    },
  },
  [`& .pendingGroupChange`]: {
    borderLeft: "2px solid #f7a452",
    backgroundColor: "rgb(247, 164, 82, 0.1) !important",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(247, 164, 82, 0.2) !important",
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "700",
    fontSize: ".92rem",
  },
  "& .MuiButton-root": {
    fontSize: ".83rem",
    color: "white",
    fontWeight: "300",
    fontFamily: "Merriweather",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Merriweather",
    // fontSize: ".87rem",
  },
  "& .MuiButton-startIcon": {
    marginRight: "3px",
  },
  "& .MuiDataGrid-cellContent": {
    fontSize: ".9rem",
  },
  "& .MuiFormControl-root": {
    color: "white",
  },
  "& .MuiInput-underline": {
    color: "white",
  },
  "& .condition-code.abg": {
    backgroundColor: "rgba(255, 255, 0, 0.4)",
  },
  "& .condition-code.dbg": {
    backgroundColor: "rgba(155, 194, 230, 0.4)",
  },
  "& .condition-code.fbg": {
    backgroundColor: "rgba(0, 176, 80, 0.4)",
  },
  "& .condition-code.gbg": {
    backgroundColor: "rgba(146, 208, 80, 0.4)",
  },
  "& .condition-code.hbg": {
    backgroundColor: "rgba(255, 0, 0, 0.4)",
  },
  "& .cosis-due-date.lessThan": {
    backgroundColor: "rgba(255, 0, 0, 0.4)",
  },
  "& .cosis-due-date.btwnTodayAndPlus7": {
    backgroundColor: "rgba(237, 125, 49, 0.4)",
  },
  "& .cosis-due-date.btwnPlus7AndPlus30": {
    backgroundColor: "rgba(255, 255, 0, 0.4)",
  },
  "& .MuiDataGrid-toolbarContainer .css-egnern-MuiSvgIcon-root": {
    color: "white !important",
  },
  "& .MuiDataGrid-toolbarContainer .MuiInputBase-root": {
    fontSize: "larger",
    width: "100%",
  },
  "& .req-delivery-date.oneToTwo": {
    backgroundColor: "rgba(255, 0, 0, 0.4)",
  },
  "& .req-delivery-date.fivePlus": {
    backgroundColor: "rgba(0, 176, 80, 0.4)",
  },
  "& .req-delivery-date.threeToFour": {
    backgroundColor: "rgba(255, 255, 0, 0.4)",
  },
}))

export const CustomStripedGrid = ({
  pathname,
  data,
  columns,
  initialState,
  title,
  loading,
  checkboxSelection,
  disableColumnSelector,
  disableColumnFilter,
  onRowSelectionModelChange,
  rowSelectionModel,
  selectedGlobalProgramId,
  handlePendingChangesCount,
  handleTransferItems,
  formattedTransferItems,
  clearSelectedRows,
  filterModel,
  rowCustomization,
  toggleFormDialog,
  handleInventoryUpdate,
  handlePendingChangesUpdate,
}) => {
  const location = useLocation()
  const apiRef = useGridApiRef()

  // NOTE: Had to include second part of condition because of an error being thrown when trying to access the "Item Transactions" view.
  const isInventory = location?.pathname?.includes("inventory") && !location?.pathname?.includes("transactions")

  const [savedState, setSavedState] = useState()

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState()
      localStorage.setItem(location?.pathname, JSON.stringify(currentState))
    }
  }, [apiRef, location?.pathname])

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem(location?.pathname)
    setSavedState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {})

    // Handle refresh and navigating away/refreshing.
    window.addEventListener("beforeunload", saveSnapshot)

    return () => {
      // In case of a SPA, remove the event-listener.
      window.removeEventListener("beforeunload", saveSnapshot)
      saveSnapshot()
    }
  }, [location?.pathname, saveSnapshot])

  // if (!savedState) {
  //   return <CircularProgress />
  // }

  let handleInvUpdate = handleInventoryUpdate || null

  let handlePendUpdate = handlePendingChangesUpdate || null

  let handlePendingCount = handlePendingChangesCount || null
  return (
    <StripedDataGrid
      apiRef={apiRef}
      initialState={initialState}
      columns={columns}
      loading={loading}
      // onFilterModelChange={onFilterModelChange}
      rows={data}
      checkboxSelection={checkboxSelection || false}
      disableColumnSelector={disableColumnSelector || false}
      disableColumnFilter={disableColumnFilter || false}
      disableRowSelectionOnClick
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      getRowClassName={(params) => {
        if (!rowCustomization) return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        else return rowCustomization(params)
      }}
      filterModel={filterModel}
      slots={isInventory ? { toolbar: CustomToolbar } : { toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          handleinventoryupdate: handleInvUpdate,
          handlependingchangesupdate: handlePendUpdate,
          handlependingchangescount: handlePendingCount,
          pathname: pathname,
          selectedglobalprogramid: selectedGlobalProgramId,
          selectedrows: formattedTransferItems,
          handletransferitems: handleTransferItems,
          toggleformdialog: toggleFormDialog,
          clearselectedrows: clearSelectedRows,
          showQuickFilter: true,
          csvOptions: {
            fileName: `${title} ${new Date().toLocaleString([], { month: "long" })} ${new Date().getFullYear()}`,
          },
          excelOptions: {
            fileName: `${title} ${new Date().toLocaleString([], { month: "long" })} ${new Date().getFullYear()}`,
            columnsStyles: {
              cosisDueDate: { numFmt: "mm/dd/yyyy" },
              cosisCompleteDate: { numFmt: "mm/dd/yyyy" },
              inventoryDate: { numFmt: "mm/dd/yyyy" },
              dateShipped: { numFmt: "mm/dd/yyyy" },
              dateReceived: { numFmt: "mm/dd/yyyy" },
              shelfLifeExpirationDate: { numFmt: "mm/dd/yyyy" },
            },
          },
        },
      }}
      density="compact"
    />
  )
}

import * as React from "react"

// ** MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { dialogPaperStyle, dialogStyle } from "../utils"

// NOTE: This component handles displaying a warning for duplicate serial numbers when adding or editing record(s).
export const DuplicatesFoundModal = ({ shouldOpen, handleConfirmClose, title, text }) => {
  const showModalText = () => {
    return (
      <>
        <p>
          The following serial number(s) already exist with the specified NSN
          <span className="highlight-blue"> ({text.nationalStockNumber}) </span>
          and P/N
          <span className="highlight-blue"> ({text.partNumber})</span>:
        </p>
        <p className="highlight-blue">{text.intersectingSerials.join(", ")}</p>
        <p>
          <span>If you wish to continue adding the duplicate(s), click </span>
          <span className="highlight-red">BYPASS</span>
          <span>, otherwise, exit out of this warning to fix the duplicate serial number(s).</span>
        </p>
      </>
    )
  }

  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="duplicates-found-dialog-title"
        fontFamily="Merriweather"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleConfirmClose()}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>{showModalText()}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleConfirmClose("bypass")}
        >
          Bypass
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { useState } from "react"

// ** MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Custom
import { dialogPaperStyle, dialogStyle } from "../utils"

export const PercentageSelectionModal = ({ shouldOpen, handleFetchMonthlyPercentageOfRecords }) => {
  const [value, setValue] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)

  const handleRadioChange = (event) => {
    if (event.target.value !== null) {
      setIsDisabled(false)
    }

    setValue(event.target.value)
  }

  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle></DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleFetchMonthlyPercentageOfRecords(false)}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Typography gutterBottom>Select a percentage of records to inventory:</Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ mb: 2 }}
            value={value}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="10"
              control={<Radio />}
              label="10%"
            />
            <FormControlLabel
              value="100"
              control={<Radio />}
              label="100%"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={isDisabled}
          onClick={() => handleFetchMonthlyPercentageOfRecords(value)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

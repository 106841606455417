// ** MUI
import { DataGridPremium } from "@mui/x-data-grid-premium"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"
import "react-toastify/dist/ReactToastify.css"

// ** Custom
import { applySignatureCaps, determineDateFieldToUpdate, formatUTCDate } from "../utils"

const SignaturesTable = (props) => {
  let sigRows = [...props.signaturesList, "finalSignature"].reduce((acc, sig, index) => {
    let dateField = determineDateFieldToUpdate(sig)

    let sigObj = {
      id: index + 1,
      type: sig,
      dateSigned: props[dateField],
      user: props[sig] === "skipped" ? "(Bypassed)" : props[sig],
    }
    acc.push(sigObj)
    return acc
  }, [])

  const sigColumns = [
    {
      field: "type",
      headerName: "Signature",
      flex: 1,
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => applySignatureCaps(params.value),
    },
    { field: "user", headerName: "Signed By", flex: 1, minWidth: 180, align: "center", headerAlign: "center" },
    {
      field: "dateSigned",
      headerName: "Date Signed",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => {
        console.log("SignaturesTable dateSigned valueFormatter: params.value", params?.value)
        return formatUTCDate(params?.value, true)
      },
    },
  ]

  console.log("sigRows inside SignaturesTable", sigRows)

  return (
    <DataGridPremium
      hideFooter
      rows={sigRows}
      columns={sigColumns}
      disableRowSelectionOnClick
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row")}
      density="compact"
      sx={{
        boxShadow: 3,
        border: "1px solid",
        borderColor: "rgba(120, 75, 132, 0.5)",
        "& .even-row.MuiDataGrid-row": {
          backgroundColor: "rgba(122, 102, 175, 0.125)",
          "&:hover, &.Mui-hovered": {
            backgroundColor: "rgba(180, 55, 87, 0.1)",
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
          },
        },
      }}
    />
  )
}

export default SignaturesTable

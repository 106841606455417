import { useContext, useState, useRef } from "react"

// ** MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import CustomProgressDialog from "./CustomProgressDialog"
import { notify, dialogStyle, dialogPaperStyle } from "../utils"

export const CSVUploadModal = ({ shouldOpen, handleClose, selectedGlobalProgramId, handleInventoryUpdate }) => {
  let context = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const csvFile = useRef(null)

  const handleFileUpload = async (file) => {
    if (file?.type === "text/csv") {
      setIsLoading(true)
      csvFile.current = file
      const selectedFileName = csvFile.current.name
      const fileReader = new FileReader()
      fileReader.onload = async () => {
        const formData = new FormData()
        formData.append("FormFile", new Blob([csvFile.current], { type: "text/csv" }), selectedFileName)
        try {
          // Add the temp file and start Hangfire task of updating data.
          await apiCalls.actionOnInventoryRecords("TempFile", formData).then(async (response) => {
            if (response.status === 200) {
              let body = {
                fileName: selectedFileName,
                programId: selectedGlobalProgramId,
                username: context.username,
                userEmail: context.userEmail,
              }

              await apiCalls.updateRecordsWithHangfire(body)
            }
          })

          setIsLoading(false)
          notify("success", "An email will be sent once all the items' Inventory Date have been updated.")
          handleClose(false)
          handleInventoryUpdate()
        } catch (error) {
          console.error(error)
          setIsLoading(false)
          notify("error", "There was a problem updating the inventory. Please try again.")
          csvFile.current = null
        }
      }
      fileReader.readAsText(csvFile.current)
    } else {
      setIsLoading(false)
      notify("error", `File type not supported. Please upload a CSV file.`)
      csvFile.current = null
    }
  }

  return (
    <div>
      {isLoading && (
        <CustomProgressDialog
          open={isLoading}
          progress={null}
          title="Updating..."
        />
      )}
      <Dialog
        open={shouldOpen}
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: dialogPaperStyle }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="csv-upload-dialog-title"
          fontFamily="Merriweather"
        >
          TIS File Import
        </DialogTitle>
        <IconButton
          aria-label="close-dialog"
          onClick={() => handleClose(false)}
          sx={dialogStyle}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Upload a <b>CSV</b> file to update the <b>Inventory Date</b> field for the items listed in an exported file from the TIS. The file should
            have the following columns: <i>Property Number, RFID, Serial Number</i> and <i>Inventory Date</i>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            component="label"
          >
            Upload
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={(e) => {
                handleFileUpload(e.target.files[0])
              }}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import { useContext, useEffect, useState } from "react"
import clsx from "clsx"

//** MUI
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

// ** Context
import AppContext from "../../AppContext"

//** Custom
import { permissionCheck, formatUTCDate } from "../../utils"

const ReviewTransferForm = ({
  initiator,
  initiationDate,
  startProgramName,
  endProgramName,
  finalRows,
  mode,
  handleTableItems,
  viewOnly,
  transfer,
  subProgramsList,
}) => {
  let context = useContext(AppContext)
  const notPermitted = permissionCheck(["Government Customer", "Program Customer", "Inventory Manager"], context.usersRoles)
  const apiRef = useGridApiRef()
  const [rows, setRows] = useState(finalRows)
  const [newProgramId, setNewProgramId] = useState("")
  const [newWarehouseId, setNewWarehouseId] = useState("")
  const [newLocation, setNewLocation] = useState("")

  const StyledDataGrid = styled("div")(() => ({
    width: "100%",
    "& .MuiDataGrid-cell--editing": {
      backgroundColor: "rgb(255,215,115, 0.19)",
      color: "#1a3e72",
      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
    "& .error-in-cell.positive": {
      backgroundColor: "rgba(255, 30, 0, 0.1)",
    },
    "& .error-in-cell.negative": {
      backgroundColor: "transparent",
    },
  }))

  useEffect(() => {
    setRows(finalRows)
  }, [finalRows])

  const warehousesList = context.warehouseData
    ?.sort((a, b) => a.buildingNumber.localeCompare(b.buildingNumber))
    .map((el) => {
      return { id: el.id, label: el.buildingNumber }
    })

  const handleChange = (e) => {
    if (e.target.name === "newWarehouseId") {
      setNewWarehouseId(e.target.value)
      massRowUpdate(e)
    } else if (e.target.name === "newProgramId") {
      setNewProgramId(e.target.value)
      massRowUpdate(e)
    } else setNewLocation(e.target.value)
  }

  // NOTE: Populate the new Warehouse, Location, and Sub-Program (as needed) fields for each row as specified by the user.
  const massRowUpdate = (e) => {
    let updatedRows
    updatedRows = rows.map((item) => {
      return {
        ...item,
        [e.target.name]: e.target.name === "newWarehouseId" ? e.target.value : e.target.name === "newProgramId" ? e.target.value : newLocation,
      }
    })

    apiRef.current.setRows(updatedRows)
    handleTableItems(updatedRows)
  }

  const columns = [
    {
      field: "newProgramId",
      headerName: "Sub-Program",
      width: 165,
      editable: !notPermitted ? true : false,
      type: "singleSelect",
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      valueOptions: () => {
        return subProgramsList
      },
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>{!value ? value : subProgramsList.find((opt) => opt.id === value).label}</div>
            <ArrowDropDownIcon />
          </Box>
        )
      },
    },
    {
      field: "newWarehouseId",
      headerName: "Warehouse",
      width: 160,
      editable: !notPermitted ? true : false,
      type: "singleSelect",
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      valueOptions: () => {
        return warehousesList
      },
      renderCell: ({ value }) => {
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>{!value ? value : warehousesList.find((opt) => opt.id === value).label}</div>
            <ArrowDropDownIcon />
          </Box>
        )
      },
    },
    {
      field: "newLocation",
      headerName: "Location",
      width: 100,
      type: "text",
      editable: !notPermitted ? true : false,
      cellClassName: (params) =>
        clsx("error-in-cell", {
          negative: params.value !== "",
          positive: params.value === "",
        }),
    },
    {
      field: "subProgramId",
      headerName: "Sub-Program",
      width: 165,
      valueFormatter: (params) => {
        let findName = !params.value ? "" : subProgramsList.find((item) => item.id === params.value).label
        return findName
      },
    },
    {
      field: "warehouseId",
      headerName: "Warehouse",
      width: 160,
      valueFormatter: (params) => {
        let findName = !params.value ? "" : warehousesList.find((item) => item.id === params.value).label
        return findName
      },
    },
    { field: "location", headerName: "Location", width: 100 },
    { field: "nationalStockNumber", headerName: "NSN", width: 100 },
    { field: "partNumber", headerName: "Part #", width: 100 },
    { field: "serialNumber", headerName: "Serial #", width: 100 },
    { field: "nomenclature", headerName: "Nomenclature", width: 170 },
    { field: "unitOfIssue", headerName: "UI", width: 60 },
    // Use either 'selectedQuantity' or 'Quantity' depending on the view. This was modified to fix negative balances that were occurring.
    { field: mode === "overview" || mode === "edit" ? "quantity" : "selectedQuantity", headerName: "Quantity", type: "number", width: 80 },
    { field: "currentBalance", headerName: "Current Balance", align: "right", width: 120 },
    { field: "conditionCode", headerName: "CC", width: 50 },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      width: 120,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 120,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
    },
    { field: "propertyNumber", headerName: "Property #", width: 100 },
  ]

  const handleRowUpdate = (updatedRow, originalRow) => {
    const updatedRows = [...finalRows]
    const rowIndex = rows.findIndex((row) => row.id === updatedRow.id)
    updatedRows[rowIndex] = updatedRow
    setRows(updatedRows)
    handleTableItems(updatedRows)
    return updatedRow
  }

  return (
    <div>
      {(mode === "review" || mode === "overview") && !viewOnly && (
        <Grid
          container
          columns={11}
        >
          <Grid
            item
            xs={2}
            sx={{ paddingTop: "30px !important", marginLeft: "9px" }}
          >
            <Typography sx={{ fontFamily: "Merriweather" }}>Please review transfer:</Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        spacing={1.5}
        columns={11}
      >
        <Grid
          item
          xs={2.5}
          sx={{ paddingTop: "30px !important" }}
        >
          <TextField
            label="Initiator"
            value={initiator}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{ paddingTop: "30px !important" }}
        >
          <TextField
            label="Initiation Date"
            value={mode === "review" ? formatUTCDate(initiationDate, false) : formatUTCDate(initiationDate, true)}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{ paddingTop: "30px !important" }}
        >
          <TextField
            label="Start Program"
            value={startProgramName}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={2.5}
          sx={{ paddingTop: "30px !important" }}
        >
          <TextField
            label="Destination Program"
            value={endProgramName}
            disabled
          />
        </Grid>
        {mode === "overview" && viewOnly && transfer.status === "COMPLETE" && (
          <>
            <Grid
              item
              xs={2.5}
              sx={{ paddingTop: "30px !important" }}
            >
              <TextField
                label="Acceptor"
                value={!transfer.acceptor ? "" : transfer.acceptor}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={2.5}
              sx={{ paddingTop: "30px !important" }}
            >
              <TextField
                label="Acceptance Date"
                value={formatUTCDate(transfer.modifiedDate, true)}
                disabled
              />
            </Grid>
          </>
        )}
        {mode === "overview" && viewOnly && transfer.status === "CANCELLED" && (
          <>
            <Grid
              item
              xs={2.5}
              sx={{ paddingTop: "30px !important" }}
            >
              <TextField
                label="Cancelled By"
                value={transfer.cancelSignature}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={2.5}
              sx={{ paddingTop: "30px !important" }}
            >
              <TextField
                label="Cancelled Date"
                value={formatUTCDate(transfer.cancelDate, true)}
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
      {mode === "edit" && !notPermitted && (
        <>
          <Grid
            container
            columns={11}
            sx={{ marginLeft: "20px" }}
          >
            <Grid
              item
              xs={11}
              sx={{ paddingTop: "30px !important" }}
            >
              <Typography
                sx={{ fontFamily: "Merriweather" }}
                align="left"
              >
                {subProgramsList !== undefined && subProgramsList.length > 0
                  ? "Enter new sub-programs, warehouses, and locations for each item in the table (or enter values for the Sub-Program, Warehouse, and Location fields below to autofill the Sub-Program, Warehouse, and Location columns in the table) before accepting the transfer:"
                  : "Enter new warehouses and locations for each item in the table (or enter values for the Warehouse and Location fields below to autofill the Warehouse and Location columns in the table) before accepting the transfer:"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1.5}
            columns={11}
            sx={{ marginLeft: "8px" }}
          >
            {/* SUB-PROGRAM SELECTION FIELD */}
            {subProgramsList !== undefined && subProgramsList.length > 0 && (
              <Grid
                item
                xs={3}
                sx={{ paddingTop: "35px !important" }}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <InputLabel
                    shrink
                    id="new-program-name"
                  >
                    Sub-Program
                  </InputLabel>
                  <Select
                    name="newProgramId"
                    required
                    labelId="new-program-select-label"
                    id="new-program-select"
                    value={newProgramId}
                    label="Sub-Program"
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        notched
                        label="Sub-Program"
                      />
                    }
                  >
                    <MenuItem
                      key="select-new-program"
                      value=""
                      disabled
                    >
                      Select
                    </MenuItem>
                    {subProgramsList?.map((el) => {
                      return (
                        <MenuItem
                          key={el.id}
                          value={el.id}
                        >
                          {el.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* WAREHOUSE SELECTION FIELD */}
            <Grid
              item
              xs={3}
              sx={{ paddingTop: "35px !important" }}
            >
              <FormControl sx={{ minWidth: "100%" }}>
                <InputLabel
                  shrink
                  id="new-warehouse-name"
                >
                  Warehouse
                </InputLabel>
                <Select
                  name="newWarehouseId"
                  required
                  labelId="new-warehouse-select-label"
                  id="new-warehouse-select"
                  value={newWarehouseId}
                  label="Warehouse"
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      notched
                      label="Warehouse"
                    />
                  }
                >
                  <MenuItem
                    key="select-new-warehouse"
                    value=""
                    disabled
                  >
                    Select
                  </MenuItem>
                  {context.warehouseData?.map((el) => {
                    return (
                      <MenuItem
                        key={el.id}
                        value={el.id}
                      >
                        {el.buildingNumber}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* LOCATION FIELD */}
            <Grid
              item
              xs={3}
              sx={{ paddingTop: "35px !important" }}
            >
              <FormControl sx={{ minWidth: "100%" }}>
                <TextField
                  name="newLocation"
                  id="new-location"
                  label="Location"
                  type="text"
                  value={newLocation}
                  onChange={handleChange}
                  onBlur={massRowUpdate}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        container
        columns={11}
      >
        <Grid
          item
          xs={11}
          sx={{ paddingTop: "20px !important" }}
        >
          {(mode === "overview" || mode === "edit") && (
            <Divider
              className="divider-disabled"
              sx={{ marginBottom: "10px" }}
            >
              {transfer.status === "COMPLETE" ? "Confirmed" : transfer.status === "CANCELLED" ? "Cancelled" : ""} Transfer Items
            </Divider>
          )}
          <StyledDataGrid>
            <DataGridPremium
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    currentBalance: mode === "review" ? true : false,
                    newLocation: mode === "edit" && !notPermitted ? true : false,
                    newWarehouseId: mode === "edit" && !notPermitted ? true : false,
                    newProgramId: mode === "edit" && !notPermitted && subProgramsList.length > 0 ? true : false,
                    location: mode === "overview" ? true : false,
                    warehouseId: mode === "overview" ? true : false,
                    subProgramId: mode === "overview" && subProgramsList.length > 0 ? true : false,
                  },
                },
              }}
              rows={rows}
              apiRef={apiRef}
              disableRowSelectionOnClick
              processRowUpdate={handleRowUpdate}
              onProcessRowUpdateError={(e) => console.log("Error", e)}
              hideFooter={"overview" ? true : false}
              sx={{
                boxShadow: 3,
                border: "1px solid",
                borderColor: "rgba(16, 80, 117, 0.5)",
                "& .even-row.MuiDataGrid-row": {
                  backgroundColor: "rgba(219, 231, 229, 0.35)",
                  "&:hover, &.Mui-hovered": {
                    backgroundColor: "rgba(84, 187, 253, 0.1)",
                    "@media (hover: none)": {
                      backgroundColor: "transparent",
                    },
                  },
                },
              }}
            />
          </StyledDataGrid>
        </Grid>
        {mode === "overview" && viewOnly && transfer.status === "CANCELLED" && (
          <Grid
            item
            xs={11}
            sx={{ paddingTop: "45px !important" }}
          >
            <FormControl sx={{ minWidth: "100%" }}>
              <TextField
                name="reason"
                id="reason"
                type="text"
                value={!transfer.cancelReason ? "" : transfer.cancelReason}
                label="Reason(s) For Cancellation"
                InputLabelProps={{ shrink: true }}
                size="small"
                multiline={true}
                rows={8}
                disabled
              />
            </FormControl>
          </Grid>
        )}
        <Grid
          item
          xs={11}
          sx={{ paddingTop: "20px !important" }}
        >
          <Divider />
        </Grid>
      </Grid>
    </div>
  )
}

export default ReviewTransferForm

import * as React from "react"
import { useState } from "react"

// ** MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { dialogPaperStyle, dialogStyle } from "../utils"

// NOTE: This component handles capturing reasoning for record changes and file deletion confirmation.
export const InventoryChangeReasonModal = ({ shouldOpen, handleConfirmClose, title, type, text }) => {
  const [reasoning, setReasoning] = useState("")
  const isPostReason = type === "add"
  const isDeleteFile = type === "deleteFile"
  const minCharacterLimit = 10

  const addBulletPoint = (e) => {
    if (e.key === "Enter") {
      setReasoning(reasoning + "• ")
    }
  }

  const showDeleteFileText = () => {
    return (
      <>
        <span>Are you sure you want to delete </span>
        <span className="highlight-blue">{text}</span>
        <span>?</span>
      </>
    )
  }

  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="inventory-change-reason-dialog-title"
        fontFamily="Merriweather"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleConfirmClose()}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {isDeleteFile ? (
          showDeleteFileText()
        ) : (
          <>
            {type === "add" && (
              <Typography
                gutterBottom
                align="left"
              >
                {text}
              </Typography>
            )}
            <FormControl sx={{ minWidth: "100%" }}>
              <TextField
                name="reason"
                id="reason"
                type="text"
                value={isPostReason ? reasoning : text}
                InputLabelProps={{ shrink: true }}
                size="small"
                multiline={true}
                rows={8}
                disabled={!isPostReason}
                onChange={(e) => setReasoning(e.target.value)}
                onKeyUp={addBulletPoint}
                onFocus={() => setReasoning("• ")} // Add so first line is bulleted.
                helperText={
                  isPostReason && (
                    <FormHelperText sx={{ width: "100%", textAlign: "right" }}>
                      {reasoning.length} characters (minimum {minCharacterLimit} characters)
                    </FormHelperText>
                  )
                }
              />
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isPostReason && (
          <Button
            variant="contained"
            onClick={() => handleConfirmClose(reasoning)}
            disabled={reasoning.length < minCharacterLimit}
          >
            Submit
          </Button>
        )}
        {isDeleteFile && (
          <Button
            variant="contained"
            color="error"
            onClick={() => handleConfirmClose(true)}
          >
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

import { useEffect, useState, useContext } from "react"

// ** Toastify
import { ToastContainer } from "react-toastify"

// ** MUI
import { IconButton, Popover, Typography } from "@mui/material"
import AnnouncementIcon from "@mui/icons-material/Announcement"
import SummarizeIcon from "@mui/icons-material/Summarize"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"
import { useAuth } from "react-oidc-context"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"
import "react-toastify/dist/ReactToastify.css"

// ** Custom
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import { DeletePendingChangeModal } from "../components/DeletePendingChangeModal"
import LoadingBackdrop, {
  getProgramName,
  filterPendingChanges,
  getWarehouseBuildingNumber,
  propertyFormatter,
  notify,
  timeRegexValidation,
  formatUTCDate,
  getFormattedDate,
} from "../utils"
import { numberInUOM } from "../context/variables"
import { InventoryChangeReasonModal } from "../components/InventoryChangeReasonModal"
import Header from "../layout/Header"

const PendingInventoryChangesView = ({ handlePendingChangesCount, leftMenuDrawerOpen }) => {
  const context = useContext(AppContext)
  const auth = useAuth()
  const [loaded, setLoaded] = useState(false)
  const [selectedItem, setSelectedItem] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedReason, setSelectedReason] = useState("")
  const [isReasonsModalOpen, setIsReasonsModalOpen] = useState(false)
  const [data, setData] = useState("")
  const [groupedChanges, setGroupedChanges] = useState()
  const [isPendingGroup, setIsPendingGroup] = useState(false)
  const [anchorPopover, setAnchorPopover] = useState()
  const [disabledButtons, setDisabledButtons] = useState([])
  const openPopover = Boolean(anchorPopover)
  const title = "Pending Changes"
  const programsDataToMap = context.isAdmin ? "allProgramsData" : "userPrograms"

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      setLoaded(true)
    }
  }, [data])

  const fetchData = async () => {
    try {
      let pendingInventoryRecordChanges = await apiCalls
        .getRecords("PendingInventoryRecordChanges")
        .then((data) => filterPendingChanges(data.data, context[programsDataToMap]))

      let groupPC = groupPendingChanges(pendingInventoryRecordChanges)
      setData(groupPC)
      handlePendingChangesCount(pendingInventoryRecordChanges.length, title)
    } catch (error) {
      console.error("Error:", error)
      if (error.response?.status === 401) {
        auth.signinRedirect()
      } else {
        notify("error", "There was a problem loading the pending changes. Please try again.")
      }
    }
  }

  // Group pending changes that have the same program, field, previous and new values.
  const groupPendingChanges = (data) => {
    let grouped = {}
    let groupedNames = []

    // Separate the changes into arrays named by the five properties below.
    for (let i = 0; i < data.length; i++) {
      let p = data[i]["program"]
      let pa = data[i]["pendingAction"]
      let f = data[i]["field"]
      let pv = data[i]["previousValue"]
      let nv = data[i]["newValue"]
      let n = data[i]["nomenclature"]
      let pn = data[i]["propertyNumber"]

      let groupNameFormat = pa === "EDIT" ? `${p}/:/${pa}/:/${f}/:/${pv}/:/${nv}` : `${p}/:/${pa}/:/${n}/:/${pn}`

      // NOTE: Do NOT group DELETE and ARCHIVE type pending changes together.
      if (!grouped[groupNameFormat]) {
        grouped[groupNameFormat] = []
        groupedNames.push(groupNameFormat)
      }

      grouped[groupNameFormat].push(data[i])
    }

    setGroupedChanges(grouped)

    // Set data to show in the table.
    let finalGroups = []
    groupedNames.forEach((indexName) => {
      if (grouped[indexName].length == 1) finalGroups.push(grouped[indexName][0])
      else {
        finalGroups.push({
          id: indexName,
          createdDate: grouped[indexName][0].createdDate,
          pendingAction: grouped[indexName][0].pendingAction,
          program: grouped[indexName][0].program,
          field: grouped[indexName][0].field,
          previousValue: grouped[indexName][0].previousValue,
          newValue: grouped[indexName][0].newValue,
          username: grouped[indexName][0].username,
        })
      }
    })
    return finalGroups
  }

  const handlePopoverOpen = (e) => {
    setAnchorPopover(e.currentTarget)
  }

  const handlePopoverClose = (e) => {
    setAnchorPopover(null)
  }

  const approvePendingAction = async (id, inventoryRecordId, pendingAction) => {
    try {
      let response = await apiCalls.patchRecord(
        inventoryRecordId,
        "InventoryRecords",
        pendingAction === "DELETE" ? "NotDeletedState" : "ActiveState",
        "replace",
        false,
        id
      )

      if (response.status === 200) {
        await removePendingChange(id)
        notify("success", "Pending action has been completed successfully.")
      }
    } catch (error) {
      console.log("Error: ", error)
      notify("error", "The pending action could not be applied to the item.")
    }
  }

  const approvePendingChange = async (id, property, value, pendingChangeId, pendingGroupChange) => {
    try {
      await apiCalls
        .patchRecord(id, "InventoryRecords", property, "replace", value, pendingChangeId)
        .then(async () => {
          // Re-calculate the Total Cost if the field changed is the Quantity OR the Unit Price.
          if (property === "Quantity" || property === "UnitPrice") await recalculateTotalCost(id, property, value)
        })
        .then(async () => {
          // If the NIIN, NSN, or P/N is changed, the record should be updated with the new FEDLOG data.
          if (property === "NationalItemIdentificationNumber" || property === "NationalStockNumber" || property === "PartNumber")
            await updateRecordWithFedLogData(id, property, value)
        })
        .then(async () => {
          // If the Warehouse is changed, change the Site (string) field to the new Warehouse's name.
          if (property === "ManagementRecordWarehouseId") {
            let buildingNumber = getWarehouseBuildingNumber(value, context.warehouseData)
            await apiCalls.patchRecord(id, "InventoryRecords", "Site", "replace", buildingNumber)
          }
        })
        .then(async () => {
          await removePendingChange(pendingChangeId)
          // Only show this message if a single record is being changed, not for a pending group change.
          if (!pendingGroupChange) notify("success", "Change has been applied to the item.")
        })
    } catch (error) {
      console.log("Error:", error)
      if (error.response?.status === 401) auth.signinRedirect()
      else notify("error", "The pending change(s) could not be saved to the database.")
    }
  }

  const updateRecordWithFedLogData = async (inventoryRecordId, field, value) => {
    // NOTE: Format field to camelCase for API call.
    await Promise.all([
      apiCalls.getById(inventoryRecordId, "InventoryRecords", ""),
      apiCalls.fedLogLookup(field[0].toLowerCase() + field.slice(1), value),
    ]).then(async ([originalRecord, fedlogData]) => {
      let or = originalRecord.data
      let fd = fedlogData.data[0]
      let updatedRecord = {
        ...originalRecord.data,
        federalSupplyClassification: setUpdatedRecordField(or.federalSupplyClassification, fd?.fsc),
        nomenclature: setUpdatedRecordField(or.nomenclature, fd?.item_name),
        nationalItemIdentificationNumber: setUpdatedRecordField(or.nationalItemIdentificationNumber, fd?.niin),
        partNumber: setUpdatedRecordField(or.partNumber, fd?.part_number),
        commercialAndGovernmentEntityCode: setUpdatedRecordField(or.commercialAndGovernmentEntityCode, fd?.cage_code),
        unitOfMeasurement: setUpdatedRecordField(or.unitOfMeasurement, fd?.um),
        sourceOfSupply: setUpdatedRecordField(or.sourceOfSupply, fd?.sos),
        unitOfIssue: setUpdatedRecordField(or.unitOfIssue, fd?.ui),
        unitPrice: setUpdatedRecordField(or.unitPrice, fd?.army_unit_price),
        accountingRequirementsCode: setUpdatedRecordField(or.accountingRequirementsCode, fd?.arc),
        controlledInventoryItemCode: setUpdatedRecordField(or.controlledInventoryItemCode, fd?.ciic),
        automaticReturnItemList: setUpdatedRecordField(or.automaticReturnItemList, fd?.aril),
        criticalityCode: setUpdatedRecordField(or.criticalityCode, fd?.critl_code),
        demilitarizationCode: setUpdatedRecordField(or.demilitarizationCode, fd?.demil_code),
        hazardousMaterielIndicatorCode: setUpdatedRecordField(or.hazardousMaterielIndicatorCode, fd?.hmic),
        recoverabilityCode: setUpdatedRecordField(or.recoverabilityCode, fd?.recov_code),
        specialControlItemCode: setUpdatedRecordField(or.specialControlItemCode, fd?.scic),
        supplyCategoriesOfMaterielCode: setUpdatedRecordField(or.supplyCategoriesOfMaterielCode, fd?.scmc),
        shelfLifeCode: setUpdatedRecordField(or.shelfLifeCode, fd?.slc),
        specialRequirementsCode: setUpdatedRecordField(or.specialRequirementsCode, fd?.src),
        materielCategoryStructureFourAndFive: setUpdatedRecordField(or.materielCategoryStructureFourAndFive, fd?.matcat_4_5),
        exchangePrice: setUpdatedRecordField(or.exchangePrice, fd?.exchange_price),
        typeOfStorageArmy: setUpdatedRecordField(or.typeOfStorageArmy, fd?.tos_army),
        hazardousCharacteristicsCode: setUpdatedRecordField(or.hazardousCharacteristicsCode, fd?.hcc),
        controlledInventoryItemCodeAmdf: setUpdatedRecordField(or.controlledInventoryItemCodeAmdf, fd?.ciic_flis),
        methodOfPreservationArmy: setUpdatedRecordField(or.methodOfPreservationArmy, fd?.army_mop),
        electrostaticDischargeCode: setUpdatedRecordField(or.electrostaticDischargeCode, fd?.esd),
        nationalStockNumber: setUpdatedRecordField(or.nationalStockNumber, fd?.fsc + fd?.niin),
      }
      await apiCalls.putRecord(updatedRecord, "InventoryRecords", inventoryRecordId)
    })
  }

  // Determines whether the original value or the FEDLOG value should be used.
  const setUpdatedRecordField = (fieldOne, fieldTwo) => {
    // The FEDLOG value should only be used if the previous value is empty.
    if (!fieldOne && fieldTwo) return fieldTwo
    else return fieldOne
  }

  const removePendingChange = async (id) => {
    try {
      await apiCalls.deleteRecord(id, "PendingInventoryRecordChanges")
      removeDisabledButton(id)
      fetchData()
    } catch (error) {
      console.error("Error:", error)
      notify("error", "The pending change could not be removed. Please try again.")
    }
  }

  const recalculateTotalCost = async (id, property, value) => {
    let originalInvItem = await apiCalls.getById(id, "InventoryRecords", "")
    let totalCost = property === "Quantity" ? originalInvItem.data.unitPrice * value : originalInvItem.data.quantity * value
    await apiCalls.patchRecord(id, "InventoryRecords", "TotalCost", "replace", totalCost)
  }

  const toggleModal = () => {
    setIsModalOpen(true)
  }

  const handleConfirmClose = () => {
    setIsReasonsModalOpen(false)
  }

  const handleConfirmDelete = (response) => {
    if (response) {
      handlePendingChangeDelete(selectedItem.id)
    }

    removeDisabledButton(selectedItem.id)
    setIsModalOpen(false)
  }

  const handleDisablingRowButtons = (rowId) => {
    setDisabledButtons([...disabledButtons, rowId])
  }

  const removeDisabledButton = (rowId) => {
    setDisabledButtons((disabledButtons) => disabledButtons.filter((btn) => btn !== rowId))
  }

  const handlePendingChangeDelete = async (id) => {
    try {
      if (!isPendingGroup) {
        await apiCalls.deleteRecord(id, "PendingInventoryRecordChanges")
        fetchData()
        notify("success", `Pending ${selectedItem.pendingAction === "EDIT" ? "change" : "action"} was deleted.`)
      } else {
        await Promise.all(
          groupedChanges[id].map(async (item) => {
            await apiCalls.deleteRecord(item.id, "PendingInventoryRecordChanges")
          })
        ).then(() => {
          fetchData()
          notify("success", "Pending group change was deleted.")
        })
      }
      setSelectedItem("")
    } catch (error) {
      console.error("Error:", error)
      notify("error", "There was a problem deleting the pending change. Please try again.")
    }
  }

  const valueReformatter = (value) => {
    // Reformat GUID Program/Warehouse values to their corresponding names.
    let pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    if (value.match(pattern)) {
      let programName = getProgramName(value, context.allProgramsData)
      let warehouseBuildingNumber = getWarehouseBuildingNumber(value, context.warehouseData)
      return programName != null ? programName : warehouseBuildingNumber
    }
    // Reformat UOMs that have a number in them.
    if (value in numberInUOM) {
      return numberInUOM[value]
    }
    return value
  }

  const columns = [
    {
      field: "createdDate",
      headerName: "Date/Time",
      minWidth: 170,
      flex: 1,
      valueFormatter: (params) => formatUTCDate(params?.value, true),
    },
    { field: "pendingAction", headerName: "Action", minWidth: 100, flex: 1 },
    { field: "program", headerName: "Program", minWidth: 100, flex: 1 },
    { field: "nomenclature", headerName: "Nomenclature", minWidth: 210, flex: 2 },
    { field: "nationalStockNumber", headerName: "NSN", minWidth: 150, flex: 1 },
    { field: "federalSupplyClassification", headerName: "FSC", minWidth: 80, flex: 1 },
    { field: "nationalItemIdentificationNumber", headerName: "NIIN", minWidth: 150, flex: 1 },
    { field: "propertyNumber", headerName: "Property Number", minWidth: 150, flex: 1 },
    {
      field: "field",
      headerName: "Property",
      minWidth: 120,
      flex: 1,
      valueFormatter: (params) => propertyFormatter(params),
    },
    {
      field: "previousValue",
      headerName: "Previous Value",
      minWidth: 205,
      flex: 2,
      valueFormatter: (params) => {
        if (timeRegexValidation(params.value)) return getFormattedDate(params.value)
        else return valueReformatter(params.value)
      },
    },
    {
      field: "newValue",
      headerName: "New Value",
      minWidth: 205,
      flex: 2,
      valueFormatter: (params) => {
        if (timeRegexValidation(params.value)) return getFormattedDate(params.value)
        else return valueReformatter(params.value)
      },
    },
    { field: "username", headerName: "Change Made By", minWidth: 160, flex: 1 },
    {
      field: "reasons",
      headerName: "Reason(s)",
      minWidth: 90,
      flex: 1,
      align: "center",
      sortable: false,
      filerable: false,
      hideable: false,
      disableExport: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.hasOwnProperty("reason") ? (
              <IconButton
                color="info"
                onClick={() => {
                  setIsReasonsModalOpen(true)
                  setSelectedReason(params.row.reason)
                }}
              >
                <SummarizeIcon />
              </IconButton>
            ) : (
              <>
                <IconButton
                  color="warning"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <AnnouncementIcon />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  sx={{ pointerEvents: "none" }}
                  open={openPopover}
                  anchorEl={anchorPopover}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        width: "300px",
                        height: "140px",
                      },
                    },
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography sx={{ p: 1, textAlign: "left" }}>
                    <em>
                      <b style={{ color: "red" }}>IMPORTANT:</b> This is a pending group change. Approving will change all the pending items with the
                      same program, property, previous and new values to the indicated new value.
                    </em>
                  </Typography>
                </Popover>
              </>
            )}
          </div>
        )
      },
    },
    {
      field: "actions",
      headerName: "Approve / Deny",
      minWidth: 150,
      flex: 1,
      align: "center",
      sortable: false,
      filerable: false,
      hideable: false,
      disableExport: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              color="success"
              onClick={async () => {
                handleDisablingRowButtons(params.row.id)
                if (params.row.hasOwnProperty("reason") && params.row.pendingAction === "EDIT")
                  approvePendingChange(params.row.inventoryRecordId, params.row.field.replaceAll(" ", ""), params.row.newValue, params.id, false)
                else if (params.row.hasOwnProperty("reason"))
                  // NOTE: For archive and delete actions.
                  approvePendingAction(params.row.id, params.row.inventoryRecordId, params.row.pendingAction)
                else {
                  await Promise.all(
                    groupedChanges[params.row.id]?.map(async (item) => {
                      approvePendingChange(item.inventoryRecordId, item.field.replaceAll(" ", ""), item.newValue, item.id, true)
                    })
                  ).then(() => notify("success", "Change has been applied to all related items."))
                }
                removeDisabledButton(params.row.id)
              }}
              disabled={disabledButtons.includes(params.row.id)}
            >
              <CheckBoxIcon />
            </IconButton>
            |
            <IconButton
              color="error"
              onClick={() => {
                handleDisablingRowButtons(params.row.id)
                setIsPendingGroup(!params.row.hasOwnProperty("reason") ? true : false)
                toggleModal()
                setSelectedItem(params.row)
              }}
              disabled={disabledButtons.includes(params.row.id)}
            >
              <DisabledByDefaultRoundedIcon />
            </IconButton>
          </div>
        )
      },
    },
  ]

  // Highlight those rows are considered a "pending group change".
  const handleRowHighlight = (params) => {
    let rowClass = params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
    let pendingGroupChange = "pendingGroupChange"
    let updatedRowClass
    if (!params.row.hasOwnProperty("reason")) {
      updatedRowClass = `${rowClass} ${pendingGroupChange}`
      return updatedRowClass
    } else return rowClass
  }

  if (loaded) {
    return (
      <>
        <Header title={title} />
        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        {isModalOpen && (
          <DeletePendingChangeModal
            shouldOpen={true}
            handleConfirmDelete={handleConfirmDelete}
            isPendingGroup={isPendingGroup}
            reference={selectedItem}
          />
        )}
        {isReasonsModalOpen && (
          <InventoryChangeReasonModal
            shouldOpen={true}
            handleConfirmClose={handleConfirmClose}
            title="Reason(s)"
            type="view"
            text={selectedReason}
          />
        )}
        <div style={{ height: "75vh", width: "100%" }}>
          <CustomStripedGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  nationalStockNumber: false,
                  federalSupplyClassification: false,
                  nationalItemIdentificationNumber: false,
                },
              },
              sorting: {
                sortModel: [{ field: "createdDate", sort: "desc" }],
              },
            }}
            data={data}
            columns={columns}
            title={title}
            rowCustomization={handleRowHighlight}
          />
        </div>
      </>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default PendingInventoryChangesView

import { useState } from "react"

// ** MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { dialogPaperStyle, dialogStyle } from "../utils"

export const CustomModal = ({ shouldOpen, handleConfirmDelete, pathname, title, reference }) => {
  const [reasoning, setReasoning] = useState("") // For pending forms.
  const minCharacterLimit = 10

  const isAPorWHView = !pathname.includes("inventory") && !pathname.includes("users") && !pathname.includes("dashboard") && pathname !== "/"
  const isUsersView = pathname.includes("users")
  const isPendingFormsView = pathname.includes("dashboard") || pathname === "/"

  const addBulletPoint = (e) => {
    if (e.key === "Enter") {
      setReasoning(reasoning + "• ")
    }
  }

  const showDeleteUserText = () => {
    return (
      <>
        <span>Are you sure you want to delete </span>
        <span className="highlight-blue">{reference}</span>
        <span>?</span>
      </>
    )
  }

  const showArchiveText = () => {
    return (
      <>
        <span>Are you sure you want to archive </span>
        <span className="highlight-blue">{reference}</span>
        <span>?</span>
      </>
    )
  }

  const showPendingFormsText = () => {
    return (
      <Typography gutterBottom>
        <span>Are you sure you'd like to cancel request </span>
        <span className="highlight-blue">{reference}</span>
        <span>? If so, please provide your reason(s) for cancelling below:</span>
      </Typography>
    )
  }

  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="custom-dialog-title"
        fontFamily="Merriweather"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleConfirmDelete(false)}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers={isPendingFormsView}>
        {isUsersView ? showDeleteUserText() : isPendingFormsView ? showPendingFormsText() : showArchiveText()}
        {isAPorWHView ? (
          <Typography color="error">
            <i>Note: All associated inventory will be archived as well.</i>
          </Typography>
        ) : isPendingFormsView ? (
          <FormControl sx={{ minWidth: "100%", minHeight: "50%" }}>
            <TextField
              name="reason"
              id="reason"
              type="text"
              value={reasoning}
              InputLabelProps={{ shrink: true }}
              size="small"
              multiline={true}
              rows={8}
              onChange={(e) => setReasoning(e.target.value)}
              onKeyUp={addBulletPoint}
              onFocus={() => setReasoning("• ")} // Add so first line is bulleted.
              helperText={
                <FormHelperText sx={{ width: "100%", textAlign: "right" }}>
                  {reasoning.length} characters (minimum {minCharacterLimit} characters)
                </FormHelperText>
              }
            />
          </FormControl>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={isUsersView || isPendingFormsView ? "error" : "primary"}
          onClick={() => (isPendingFormsView ? handleConfirmDelete(true, reasoning) : handleConfirmDelete(true))}
          disabled={isPendingFormsView && reasoning.length < minCharacterLimit}
        >
          {isUsersView ? "Delete" : isPendingFormsView ? "Confirm" : "Archive"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { useContext, useEffect, useState } from "react"

// ** MUI
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "../components/styles/CustomFormStyles.css"

// ** Custom
import { getProgramName } from "../utils"
import CartItemsGrid from "./CartItemsGrid"
import { states } from "../context/variables"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"

const DC001CustomForm = ({ cartItems, handleDeleteItemFromCart, handleSubmitForm, handleDeleteAllCartItems }) => {
  const context = useContext(AppContext)
  const [requestDate, setRequestDate] = useState(dayjs.utc().format("YYYY-MM-DDTHH:mm:ss[Z]"))
  const [requestedBy, setRequestedBy] = useState("")
  const [programId, setProgramId] = useState("")
  const [pocName, setPocName] = useState("")
  const [pocEmail, setPocEmail] = useState("")
  const [pocPhone, setPocPhone] = useState("")
  const [priority, setPriority] = useState("")
  const [requiredDeliveryDate, setRequiredDeliveryDate] = useState(null)
  const [requestType, setRequestType] = useState("")
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [activityName, setActivityName] = useState("")
  const [address, setAddress] = useState("")
  const [isConus, setIsConus] = useState(true)
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [country, setCountry] = useState("")
  const [DODAAC, setDODAAC] = useState("")
  const [cartItemsToDisplay, setCartItemsToDisplay] = useState("")
  const [errors, setErrors] = useState("")
  const [estimatedTotalPrice, setEstimatedTotalPrice] = useState("")
  const [datePickerHelperText, setDatePickerHelperText] = useState({
    requestDate: "",
    requiredDeliveryDate: "",
  })
  const isRequestLocal = requestType.includes("Movement") || requestType.includes("Pickup")

  useEffect(() => {
    setRequestedBy(context.fullName)
  }, [requestedBy])

  useEffect(() => {
    let filteredItemsQtyWiped = [...cartItems]
      .filter((item) => programId === item.managementRecordArmyProgramId)
      .map((item) => {
        if (!item.serialNumber || item.serialNumber === "NA" || item.serialNumber === "N/A") {
          return {
            ...item,
            quantity: 0,
          }
        } else {
          return {
            ...item,
          }
        }
      })

    setCartItemsToDisplay(filteredItemsQtyWiped)
  }, [programId, cartItems])

  useEffect(() => {
    const estimatedTotalPrice = [...cartItemsToDisplay].reduce((acc, item) => {
      acc += item.totalCost
      return acc
    }, 0)
    setEstimatedTotalPrice(estimatedTotalPrice)
    if (!cartItemsToDisplay.length) {
      setProgramId("")
    }
  }, [cartItemsToDisplay])

  console.log("isRequestLocal", isRequestLocal)

  useEffect(() => {
    if (requestDate && requiredDeliveryDate) {
      validateDates(dayjs.utc(requestDate), dayjs.utc(requiredDeliveryDate))
    }
  }, [requestDate, requiredDeliveryDate, isRequestLocal])

  const handleCartItemsDisplayed = (updatedItems) => {
    setCartItemsToDisplay(updatedItems)
  }

  const formatCartItemPrograms = (cartItems) => {
    let cartItemProgramIds = [...cartItems].reduce((acc, item) => {
      if (!acc.includes(item.managementRecordArmyProgramId)) {
        acc.push(item.managementRecordArmyProgramId)
      }
      return acc
    }, [])

    const programDataToIterate = context.allProgramsData
    const cartPrograms = new Set(cartItemProgramIds)
    const cartProgramData = programDataToIterate?.filter((el) => cartPrograms.has(el.id.toString()))
    return cartProgramData
  }

  const programSelectOptions = formatCartItemPrograms(cartItems)?.map((program) => {
    return (
      <MenuItem
        key={program.id}
        value={program.id}
      >
        {program.name}
      </MenuItem>
    )
  })

  const requestTypes = [
    "Movement Request - Local Delivery",
    "Pickup Request - Local Pickup only",
    "Pull Request - Assets requested to be shipped",
    "Turn-in Request - Assets requested to be Turned-in/DRMO",
  ]

  const requestTypeOptions = requestTypes.map((type) => {
    return (
      <MenuItem
        key={type}
        value={type}
      >
        {type}
      </MenuItem>
    )
  })

  const handleFormattingGridItems = async () => {
    return [...cartItemsToDisplay].map((item) => {
      return {
        assetIdentification: item.assetIdentification,
        inventoryRecordId: item.id,
        nationalStockNumber: item.nationalStockNumber,
        hazardousMaterielIndicatorCode: item.hazardousMaterielIndicatorCode,
        partNumber: item.partNumber,
        serialNumber: item.serialNumber,
        nomenclature: item.nomenclature,
        unitOfIssue: item.unitOfIssue,
        quantity: item.quantity,
        conditionCode: item.conditionCode,
        unitPrice: item.unitPrice,
        totalCost: item.totalCost,
        location: item.location,
        propertyNumber: item.propertyNumber,
      }
    })
  }

  const handleFormattingForSubmit = async () => {
    const requestCartItems = await handleFormattingGridItems()
    const formObjToPost = {
      requestDate: requestDate,
      requestedBy: requestedBy,
      programId: programId,
      programName: getProgramName(programId, context.allProgramsData),
      requestType: requestType,
      pocName: pocName,
      pocEmail: pocEmail,
      pocPhone: pocPhone,
      priority: priority,
      requiredDeliveryDate: requiredDeliveryDate,
      specialInstructions: specialInstructions,
      estimatedTotalPrice: estimatedTotalPrice,
      dodAac_Uic: DODAAC,
      address: address,
      activityName: activityName,
      isConus: isConus,
      city: city,
      state: state,
      zipCode: zipCode,
      country: country,
      requestItems: requestCartItems,
    }
    await validateFormFields(formObjToPost)

    await handleSubmitForm(formObjToPost, cartItemsToDisplay)
  }

  const validateDates = async (reqDate, reqDelivDate) => {
    const isReqDateValid = dayjs.utc(reqDate).isValid()
    const isRDDValid = dayjs.utc(reqDelivDate).isValid()
    const isSameDayAllowed = isRequestLocal

    if (!isRDDValid || !isReqDateValid) {
      return
    }

    const isSameDay = dayjs.utc(reqDate).isSame(dayjs.utc(reqDelivDate), "day")
    const isReqDateAfter = dayjs.utc(reqDate).isAfter(dayjs.utc(reqDelivDate))
    console.log("isSameDay", isSameDayAllowed, "isSameDay", isSameDay, "isReqAfter", isReqDateAfter)

    // If same-day is not allowed and request date is after or same as delivery date
    if (!isSameDayAllowed && (isSameDay || isReqDateAfter)) {
      setDatePickerHelperText({
        requestDate: "* Request Date must be before Required Delivery Date.",
        requiredDeliveryDate: "* Required Delivery Date must be after Request Date.",
      })
      setPriority("") // Clear priority if validation fails
      return
    }

    // Clear errors if validation passes
    setDatePickerHelperText({
      requestDate: "",
      requiredDeliveryDate: "",
    })

    // Auto-assign priority
    const daysBetween = dayjs.utc(reqDelivDate).diff(dayjs.utc(reqDate), "day")

    if (daysBetween <= 2) {
      setPriority("High - (1-2 Day Shipping)")
    } else if (daysBetween <= 5) {
      setPriority("Medium - (3-5 Day Shipping)")
    } else {
      setPriority("Standard - (5-7 Day Shipping)")
    }
  }

  const handleDateChange = async (newValue, fieldName) => {
    const isDateValid = newValue && dayjs.utc(newValue).isValid()
    if (!isDateValid) {
      setDatePickerHelperText((prev) => ({
        ...prev,
        [fieldName]: `* Invalid Date selected.`,
      }))
      return
    }

    // Format as Zulu time without offsets
    const formattedUTCDate = dayjs.utc(newValue).format("YYYY-MM-DDTHH:mm:ss[Z]")

    // Update state based on the field name
    if (fieldName === "requestDate") {
      setRequestDate(formattedUTCDate)
    } else if (fieldName === "requiredDeliveryDate") {
      setRequiredDeliveryDate(formattedUTCDate)
    }

    // Clear errors and validate dates
    setDatePickerHelperText((prev) => ({
      ...prev,
      [fieldName]: "",
    }))

    await validateDates(
      fieldName === "requestDate" ? dayjs.utc(newValue) : dayjs.utc(requestDate),
      fieldName === "requiredDeliveryDate" ? dayjs.utc(newValue) : dayjs.utc(requiredDeliveryDate)
    )
  }

  // Handler for radio button change.
  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value === "CONUS"
    setIsConus(selectedLocation)

    // Clear fields when switching between CONUS and OCONUS.
    setAddress("")
    setCity("")
    setState("")
    setZipCode("")
    setCountry("")
  }

  const handleLocationBlur = (e) => {
    const { name, value } = e.target
    if (isConus === true) {
      if (name === "city" || name === "state" || name === "zipCode") {
        setErrors((prevState) => ({ ...prevState, [name]: !value }))
      }
    } else if (!isConus) {
      if (name === "city" || name === "country") {
        setErrors((prevState) => ({ ...prevState, [name]: !value }))
      }
    }
  }

  const validateFormFields = async (formObj) => {
    const formObjKeys = Object.keys(formObj)
    formObjKeys.forEach((key) => {
      let isSpecialInstructions = key === "specialInstructions"
      let isNotSIAndNoValue = !isSpecialInstructions && !formObj[key]
      if ((isRequestLocal && isSpecialInstructions && !formObj[key]) || isNotSIAndNoValue) {
        setErrors((prevState) => ({ ...prevState, [key]: true }))
      }
      if ((isSpecialInstructions && !isRequestLocal && !formObj[key]) || formObj[key]) {
        setErrors((prevState) => ({ ...prevState, [key]: false }))
      }
    })
  }

  const handleValidate = (e) => {
    let isSpecialInstructions = e.target.name === "specialInstructions"
    let isNotSIAndNoValue = !isSpecialInstructions && !e.target.value
    if ((isRequestLocal && isSpecialInstructions && !e.target.value) || isNotSIAndNoValue) {
      setErrors((prevState) => {
        return {
          ...prevState,
          [e.target.name]: true,
        }
      })
    } else if ((isSpecialInstructions && !isRequestLocal && !e.target.value) || e.target.value) {
      setErrors((prevState) => {
        return {
          ...prevState,
          [e.target.name]: false,
        }
      })
    }
  }

  const disableWeekends = (date) => {
    const day = dayjs(date).day()
    return day === 0 || day === 6
  }

  let conditionToDisplayMessage = !cartItemsToDisplay.length && !programId

  const checkCartItems = () => {
    return cartItemsToDisplay.find((item) => {
      if (!item.quantity) {
        return true
      } else {
        return false
      }
    })
  }

  const checkCONUSFields = () => {
    if (requestType.includes("Pickup")) return false
    else {
      if ((isConus && !city) || (isConus && !state) || (isConus && !zipCode)) {
        return true
      }

      if ((!isConus && !city) || (!isConus && !country)) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <div style={{ width: "100%", minHeight: 500 }}>
      <Grid
        container
        rowSpacing={2.5}
        columns={16}
        justifyContent="space-evenly"
        alignContent="space-evenly"
        sx={{ width: "100%", minHeight: "45vh" }}
      >
        {/*  REQUEST DATE */}
        <Grid
          item
          xs={3}
        >
          <FormControl
            required
            sx={{ minWidth: "100%" }}
            error={!!datePickerHelperText.requestDate}
          >
            <DatePicker
              name="requestDate"
              id="request-date"
              margin="dense"
              notched
              label="Request Date"
              value={dayjs.utc(requestDate)}
              disablePast
              required
              shouldDisableDate={disableWeekends}
              onChange={(newValue) => handleDateChange(newValue, "requestDate")}
              slotProps={{
                textField: {
                  helperText: datePickerHelperText.requestDate,
                  error: !!datePickerHelperText.requestDate,
                  required: true,
                },
              }}
            />
          </FormControl>
        </Grid>

        {/* REQUESTED BY */}
        <Grid
          item
          xs={4}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="requestedBy"
              id="requested-by"
              label="Requested By"
              type="text"
              value={requestedBy}
              onChange={(e) => setRequestedBy(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.requestedBy}
              onBlur={handleValidate}
              helperText={errors.requestedBy && "* Required"}
            />
          </FormControl>
        </Grid>

        {/* REQUEST TYPE */}
        <Grid
          item
          xs={8}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel
              shrink
              id="request-type"
              required
              error={!!errors.requestType}
            >
              Request Type
            </InputLabel>
            <Select
              name="requestType"
              labelId="request-select-label"
              id="request-select"
              value={requestType}
              label="Request Type"
              required
              onChange={(e) => {
                if (e.target.value.includes("Pickup")) {
                  // Reset all address-related fields.
                  setAddress("")
                  setCity("")
                  setState("")
                  setZipCode("")
                  setCountry("")
                  setIsConus(null)
                } else if (e.target.value.includes("Movement")) {
                  // NOTE: Local deliveries should be CONUS.
                  setCountry("")
                  setIsConus(true)
                } else {
                  setIsConus(true)
                }

                setRequestType(e.target.value)
              }}
              onBlur={handleValidate}
              error={errors.requestType}
              input={
                <OutlinedInput
                  notched
                  label="Request Type"
                  error={!!errors.requestType}
                />
              }
            >
              <MenuItem
                key="select-request"
                value=""
                disabled
              >
                Select
              </MenuItem>
              {requestTypeOptions}
            </Select>
            {errors.requestType && <FormHelperText error>* Required</FormHelperText>}
          </FormControl>
        </Grid>

        {/* POC NAME */}
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="pocName"
              id="poc-name"
              label="POC Name"
              type="text"
              value={pocName}
              onChange={(e) => setPocName(e.target.value)}
              InputLabelProps={{ shrink: true }}
              error={errors.pocName}
              onBlur={handleValidate}
              helperText={errors.pocName && "* Required"}
              required
            />
          </FormControl>
        </Grid>

        {/* POC EMAIL */}
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="pocEmail"
              id="poc-email"
              label="POC Email"
              type="text"
              value={pocEmail}
              onChange={(e) => setPocEmail(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.pocEmail}
              onBlur={handleValidate}
              helperText={errors.pocEmail && "* Required"}
            />
          </FormControl>
        </Grid>

        {/* POC PHONE */}
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="pocPhone"
              id="poc-phone"
              label="POC Phone"
              type="text"
              value={pocPhone}
              onChange={(e) => setPocPhone(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={!!errors.pocPhone}
              onBlur={handleValidate}
              helperText={errors.pocPhone && "* Required"}
            />
          </FormControl>
        </Grid>

        {/* PROGRAM NAME */}
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel
              shrink
              id="program-name"
              required
              error={!!errors.programName}
            >
              Program Name
            </InputLabel>
            <Select
              name="programName"
              required
              labelId="program-select-label"
              id="program-select"
              value={programId}
              label="Program Name"
              onChange={(e) => setProgramId(e.target.value)}
              onBlur={handleValidate}
              error={errors.programName}
              input={
                <OutlinedInput
                  notched
                  label="Program Name"
                />
              }
            >
              <MenuItem
                key="select-program"
                value=""
                disabled
              >
                Select
              </MenuItem>
              {programSelectOptions}
            </Select>
            {(errors.programName && <FormHelperText error>* Please select a Program.</FormHelperText>) ||
              (conditionToDisplayMessage && <FormHelperText primary="true">* Please select a Program.</FormHelperText>)}
          </FormControl>
        </Grid>
        {/* REQUIRED DELIVERY DATE */}
        <Grid
          item
          xs={5}
        >
          <FormControl
            error={!!datePickerHelperText.requiredDeliveryDate}
            required
            sx={{ minWidth: "100%" }}
          >
            <DatePicker
              name="requiredDeliveryDate"
              id="required-delivery-date"
              margin="dense"
              notched
              label="Required Delivery Date"
              value={requiredDeliveryDate ? dayjs.utc(requiredDeliveryDate) : null}
              disablePast
              required
              slotProps={{
                textField: {
                  InputLabelProps: {
                    shrink: true,
                    required: true,
                  },
                  helperText: datePickerHelperText.requiredDeliveryDate,
                  error: !!datePickerHelperText.requiredDeliveryDate,
                },
              }}
              shouldDisableDate={disableWeekends}
              onChange={(newValue) => handleDateChange(newValue, "requiredDeliveryDate")}
            />
          </FormControl>
        </Grid>

        {/* PRIORITY */}
        <Grid
          item
          xs={5}
        >
          <FormControl fullWidth>
            <TextField
              name="priority"
              label="Priority"
              value={priority}
              InputProps={{
                readOnly: true,
                disabled: true,
              }}
              InputLabelProps={{ shrink: true }}
              helperText="Priority is automatically determined based on selected dates."
            />
          </FormControl>
        </Grid>

        {/* SPECIAL INSTRUCTIONS */}
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            {/* Should this field be called something else? Originally Fernando said it was for local requests only. */}
            <TextField
              name="specialInstructions"
              id="special-instructions"
              label="Special Instructions"
              type="text"
              value={specialInstructions}
              onChange={(e) => setSpecialInstructions(e.target.value)}
              InputLabelProps={{ shrink: true }}
              placeholder="Anything to note for this request?"
              required={isRequestLocal}
              error={errors.specialInstructions}
              onBlur={handleValidate}
              helperText={errors.specialInstructions && "* Required"}
            />
          </FormControl>
        </Grid>

        {/* DODAAC/UIC */}
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              id="dodAac_Uic"
              label="DODAAC/UIC"
              name="dodAac_Uic"
              type="text"
              value={DODAAC}
              onChange={(e) => setDODAAC(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.dodAac_Uic}
              onBlur={handleValidate}
              helperText={errors.dodAac_Uic && "* Required"}
            />
          </FormControl>
        </Grid>

        {/* ACTIVITY NAME */}
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="activityName"
              id="activityName"
              label="Activity Name"
              type="text"
              value={activityName}
              onChange={(e) => setActivityName(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.activityName}
              onBlur={handleValidate}
              helperText={errors.activityName && "* Required"}
            />
          </FormControl>
        </Grid>

        {!requestType.includes("Pickup") && (
          <>
            {/* LOCATION TYPE */}
            <Grid
              item
              xs={12}
              sm={3}
            >
              <FormControl
                component="fieldset"
                fullWidth
              >
                <FormLabel component="legend">Location Type</FormLabel>
                <RadioGroup
                  row
                  name="locationType"
                  value={isConus === true ? "CONUS" : "OCONUS"}
                  onChange={handleLocationChange}
                  sx={{ justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    value="CONUS"
                    control={<Radio />}
                    label="CONUS"
                  />
                  <FormControlLabel
                    value="OCONUS"
                    control={<Radio />}
                    label="OCONUS"
                    disabled={requestType.includes("Movement")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* ADDRESS */}
            <Grid
              item
              xs={4}
            >
              <FormControl sx={{ minWidth: "100%" }}>
                <TextField
                  id="address"
                  name="address"
                  label="Address"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  required={!requestType.includes("Pickup")}
                  error={errors.address}
                  onBlur={handleValidate}
                  helperText={errors.address && "* Required"}
                />
              </FormControl>
            </Grid>
          </>
        )}

        {/* CONDITIONAL RENDERING FOR CONUS. */}
        {isConus && !requestType.includes("Pickup") && (
          <>
            {/* CITY */}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                label="City"
                name="city"
                value={city}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setCity(e.target.value)}
                onBlur={handleLocationBlur}
                error={!!errors.city}
                helperText={errors.city && "* Required"}
                fullWidth
                required
              />
            </Grid>

            {/* STATE */}
            <Grid
              item
              xs={12}
              sm={1.75}
            >
              <TextField
                label="State"
                name="state"
                select
                value={state}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setState(e.target.value)}
                onBlur={handleLocationBlur}
                error={!!errors.state}
                helperText={errors.state && "* Required"}
                fullWidth
                required
              >
                {states.map((stateCode) => (
                  <MenuItem
                    key={stateCode}
                    value={stateCode}
                  >
                    {stateCode}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* ZIP CODE */}
            <Grid
              item
              xs={12}
              sm={1.75}
            >
              <TextField
                label="Zip Code"
                name="zipCode"
                InputLabelProps={{ shrink: true }}
                onBlur={handleLocationBlur}
                error={!!errors.zipCode}
                helperText={errors.zipCode && "* Required"}
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                fullWidth
                required
              />
            </Grid>
          </>
        )}

        {/* CONDITIONAL RENDERING FOR OCONUS. */}
        {!isConus && !requestType.includes("Pickup") && (
          <>
            {/* CITY */}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                name="city"
                label="City"
                value={city}
                InputLabelProps={{ shrink: true }}
                onBlur={handleLocationBlur}
                error={!!errors.city}
                helperText={errors.city && "* Required"}
                onChange={(e) => setCity(e.target.value)}
                fullWidth
                required
              />
            </Grid>

            {/* COUNTRY */}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                name="country"
                label="Country"
                value={country}
                InputLabelProps={{ shrink: true }}
                onBlur={handleLocationBlur}
                error={!!errors.country}
                helperText={errors.country && "* Required"}
                onChange={(e) => setCountry(e.target.value)}
                fullWidth
                required
              />
            </Grid>
          </>
        )}

        {/* EMPTY */}
        <Grid
          item
          xs={10.25}
        />

        {/* ESTIMATED TOTAL PRICE */}
        <Grid
          item
          xs={5}
        >
          <FormControl
            disabled
            sx={{ minWidth: "100%" }}
          >
            <TextField
              name="estimatedTotalPrice"
              id="estimated_total_price"
              label="Estimated Total Price"
              type="text"
              value={Intl.NumberFormat("en-US", {
                maximumFractionsDigits: 2,
                minimumFractionDigits: 2,
              }).format(estimatedTotalPrice)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              disabled
            />
          </FormControl>
        </Grid>

        {/* FOOT NOTE */}
        {conditionToDisplayMessage && <p className="info-blurb">Select a program from Program Name to display cart items.</p>}

        {/* CART ITEMS */}
        {cartItemsToDisplay.length > 0 && (
          <Grid
            item
            xs={15.5}
          >
            <div style={{ minHeight: 200, marginTop: "1rem" }}>
              <CartItemsGrid
                cartItemsToDisplay={cartItemsToDisplay}
                handleDeleteItemFromCart={handleDeleteItemFromCart}
                handleDeleteAllCartItems={handleDeleteAllCartItems}
                handleCartItemsDisplayed={handleCartItemsDisplayed}
              />
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <Button
                onClick={handleFormattingForSubmit}
                variant="contained"
                disabled={!!checkCartItems() || !requiredDeliveryDate || !!checkCONUSFields()}
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  width: "200px",
                  backgroundColor: "#105075",
                  border: "2px solid #646464",
                  marginRight: "1rem",
                }}
              >
                Submit
              </Button>
              <Tooltip
                title={
                  <Typography
                    fontSize={16}
                    fontStyle="italic"
                    textAlign="left"
                  >
                    <b>IMPORTANT:</b> This will remove <u>all</u> items from your cart for the current program listed. Only click if you wish to
                    proceed with removing all of the listed cart items above.
                  </Typography>
                }
                arrow
                followCursor
              >
                <Button
                  onClick={() => handleDeleteAllCartItems(programId)}
                  variant="contained"
                  disabled={!cartItemsToDisplay.length}
                  sx={{
                    marginLeft: "1rem",
                    color: "darkred",
                    fontWeight: "bold",
                    width: "200px",
                    backgroundColor: "#c2630aa1",
                    border: "2px solid darkred",
                    ":hover": {
                      backgroundColor: "#913138",
                      color: "white",
                    },
                  }}
                >
                  Remove All Items
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default DC001CustomForm

import { useState } from "react"

// ** MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { dialogPaperStyle, dialogStyle, getBase64, notify } from "../utils"
import DocumentsInputForm from "./DocumentsInputForm"

export const DocumentAttachmentModal = ({
  shouldOpen,
  handleClose,
  selectedRowsIds,
  programName,
  documentRows,
  handleDocumentRows,
  clearSelectedRows,
}) => {
  const [buttonPressed, setButtonPressed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleDocumentAttachment = async () => {
    setButtonPressed(true)
    setIsLoading(true)
    let documentIds = []
    await Promise.all(
      documentRows.map(async (el) => {
        await new Promise(getBase64(el.fileObject)).then(async (result) => {
          let basicRequest = {
            bucketName: "awims",
            objectName: el.fileName,
            filePath: `${programName}/Inventory Documents`,
            file: result,
          }

          try {
            let response = await apiCalls.putFile(basicRequest)
            documentIds.push(response.data.result.value.id)
          } catch (error) {
            console.error("Error:", error)
          }
        })
      })
    )
      .then(async () => {
        // Once document(s) are added, create relationship between record and document(s).
        await Promise.all(
          documentIds.map((docId) => {
            return selectedRowsIds.map(async (el) => {
              let request = {
                inventoryRecordId: el,
                documentId: docId,
              }

              await apiCalls.postRecord(request, "AttachmentRecords")
            })
          })
        )

        handleClose(false)
        notify("success", "Files were successfully attached to selected items.")
        clearSelectedRows()
      })
      .catch((error) => {
        notify("error", "Something went wrong when attaching the files.")
        console.error("Error in handleDocumentAttachment():", error)
      })
  }

  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="attach-docs-dialog-title"
        fontFamily="Merriweather"
      >
        Attach Document(s)
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleClose(false)}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography
          gutterBottom
          align="left"
        >
          Attach any number of documents in the table below to the records previously selected in the <i>Inventory Records</i> table:
        </Typography>
        <DocumentsInputForm
          operation="multipleRecordUpload"
          type="inventoryRecord"
          programName={programName}
          recordId={null}
          finalDocumentRows={documentRows}
          handleFinalDocumentRows={handleDocumentRows}
          handleFileConversion={getBase64}
          isLoading={isLoading}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleDocumentAttachment()}
          disabled={!documentRows.length || buttonPressed}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { useContext, useState } from "react"

// ** MUI
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { dialogPaperStyle, dialogStyle, notify } from "../utils"

export const RelocateItemsModal = ({
  handlePendingChangesUpdate,
  shouldOpen,
  handleClose,
  itemsToRelocate,
  clearSelectedRows,
  selectedGlobalProgramId,
  handlePendingChangesCount,
}) => {
  let context = useContext(AppContext)
  const [warehouseId, setWarehouseId] = useState("")
  const [location, setLocation] = useState("")
  const [checked, setChecked] = useState({ warehouseCheck: false, locationCheck: false })
  const warehousesList = context.warehouseData.map((el) => {
    return { id: el.id, label: el.buildingNumber }
  })

  const handleChange = async (event, key) => {
    if (event.target.name === "warehouse")
      setWarehouseId(key.key.slice(2)) // Remove .$ from the key.
    else if (event.target.name === "location") setLocation(event.target.value)
  }

  const handleCheckboxChange = (event) => {
    let fieldName = event.target.name
    setChecked({ ...checked, [fieldName]: event.target.checked })

    fieldName === "warehouseCheck" ? setWarehouseId("") : setLocation("")
  }

  const handleDisableButton = () => {
    if (!checked.warehouseCheck && !checked.locationCheck) return true
    else if (checked.warehouseCheck && !warehouseId) return true
    else if (checked.locationCheck && location === "") return true
    else return false
  }

  const handleManagementRecord = async () => {
    let response = await apiCalls.getManagementRecords(
      `?%24filter=WarehouseId%20eq%20${warehouseId}%20and%20ArmyProgramId%20eq%20${selectedGlobalProgramId}`
    )

    if (response.data.value.length === 0) {
      let body = {
        armyProgramId: selectedGlobalProgramId,
        warehouseId: warehouseId,
      }
      return await apiCalls.postRecord(body, "ManagementRecords")
    } else return response
  }

  const handleRelocateItems = async () => {
    try {
      let pendingChangesCount = 0
      let relocateResponse
      if (checked.warehouseCheck) {
        await handleManagementRecord()
        relocateResponse = await Promise.all(
          itemsToRelocate.map(async (item) => {
            let newItem = checked.locationCheck
              ? {
                  ...item,
                  Location: location,
                  ManagementRecordWarehouseId: warehouseId,
                }
              : { ...item, ManagementRecordWarehouseId: warehouseId }
            return await apiCalls.putPendingRecordChanges(newItem, "InventoryRecords", newItem.id)
          })
        )
      } else {
        relocateResponse = await Promise.all(
          itemsToRelocate.map(async (item) => {
            let newItem = {
              ...item,
              Location: location,
            }
            return await apiCalls.putPendingRecordChanges(newItem, "InventoryRecords", newItem.id)
          })
        )
      }

      notify("success", "Changes have successfully been marked as pending for Administrator approval.")
      handleClose(false)
      clearSelectedRows()
      handlePendingChangesUpdate()

      /*
       * NOTE: The differences are counted in the BE and returned in the response body.
       * It's used to get the accurate amount for the pending changes badge.
       */
      relocateResponse.forEach((item) => {
        pendingChangesCount += item.data.differencesCount
      })
      handlePendingChangesCount(pendingChangesCount, "Inventory")
    } catch (error) {
      console.log("ERROR:", error)
      notify("error", "The item(s) could not be relocated.")
      handleClose(false)
    }
  }

  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="relocate-items-dialog-title"
        fontFamily="Merriweather"
      >
        Relocate Item(s)
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleClose(false)}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>Select which field(s) you'd like to change for the selected item(s) and enter a value for each:</Typography>
        <FormControl
          sx={{ m: 3 }}
          component="fieldset"
          variant="standard"
        >
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.warehouseCheck}
                  onChange={handleCheckboxChange}
                  name="warehouseCheck"
                />
              }
              label="Warehouse"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.locationCheck}
                  onChange={handleCheckboxChange}
                  name="locationCheck"
                />
              }
              label="Location"
            />
          </FormGroup>
        </FormControl>

        {/* WAREHOUSE DROPDOWN */}
        {checked.warehouseCheck && (
          <FormControl sx={{ m: 1, minWidth: "80%" }}>
            <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
            <Select
              id="warehouse"
              name="warehouse"
              label="Warehouse"
              onChange={handleChange}
              MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            >
              {warehousesList.map((el) => {
                return (
                  <MenuItem
                    key={el.id}
                    value={el.label}
                  >
                    {el.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {/* LOCATION FIELD */}
        {checked.locationCheck && (
          <FormControl sx={{ minWidth: "80%", marginTop: checked.warehouseCheck ? "10px" : "0px" }}>
            <TextField
              id="location"
              name="location"
              label="Location"
              type="text"
              value={location}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={handleDisableButton()}
          onClick={handleRelocateItems}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { useState } from "react"

// ** MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import ErrorIcon from "@mui/icons-material/Error"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { dialogPaperStyle, dialogStyle } from "../utils"

export const ConfirmationModal = ({ isCancelled, shouldOpen, handleChange, handleUpdateWRT, referenceWRT }) => {
  const [open, setOpen] = useState(shouldOpen)
  const [reasoning, setReasoning] = useState("") // For cancelled reasoning and notes.
  const [radioValue, setRadioValue] = useState(referenceWRT.shippingCost === 0 ? "thirdParty" : "prepaid")
  const minCharacterLimit = 10

  const handleValidTACOrSLIN = (type, value) => {
    let pattern
    if (type === "TAC") {
      // NOTE: TAC must be four characters comprised of only uppercase letters and digits.
      pattern = new RegExp(/^$|(\b[A-Z0-9]{4}\b)$/)
    } else {
      // NOTE: SLIN must be six digits.
      pattern = new RegExp(/^$|(\b[\d]{6}\b)$/)
    }

    return pattern.test(value) || value === null ? false : true
  }

  const handleValidMonetaryValue = (value) => {
    // NOTE: Shipping cost cannot be a negative value, 0, 0.00, go past two decimal places, or not have any numbers after a decimal.
    let pattern = new RegExp(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/)
    return pattern.test(value) || value === null ? false : true
  }

  const handleClose = () => setOpen(false)
  let determineHeader = referenceWRT.isLocal ? "Local Delivery / Pickup" : "Shipment Information"

  /*
   * NOTE: If "Third-Party" is selected, then only the Tracking Number and Carrier are required for submission.
   * If "Prepaid", Tracking Number, Carrier, Shipping Cost, and EITHER TAC or SLIN are required for submission.
   */
  let determineButtonDisabled =
    (!referenceWRT.isLocal && !referenceWRT.trackingNumber) ||
    (!referenceWRT.isLocal && !referenceWRT.carrierInformation) ||
    (!referenceWRT.isLocal && radioValue === "prepaid" && !referenceWRT.transportationAccountCode && !referenceWRT.sublineItemNumber) ||
    (!referenceWRT.isLocal && radioValue === "prepaid" && handleValidTACOrSLIN("TAC", referenceWRT.transportationAccountCode)) ||
    (!referenceWRT.isLocal && radioValue === "prepaid" && handleValidTACOrSLIN("SLIN", referenceWRT.sublineItemNumber)) ||
    (!referenceWRT.isLocal && radioValue === "prepaid" && (!referenceWRT.shippingCost || handleValidMonetaryValue(referenceWRT.shippingCost)))

  let isPrepaid = radioValue === "prepaid"

  const handleRadioChange = (event) => {
    if (event.target.value === "prepaid") referenceWRT.shippingCost = ""
    else {
      referenceWRT.transportationAccountCode = ""
      referenceWRT.sublineItemNumber = ""
      referenceWRT.shippingCost = "0.00"
    }
    setRadioValue(event.target.value)
  }

  const handleConfirmClick = (response, reasoning) => {
    handleUpdateWRT(response, reasoning)
    handleClose()
  }

  const addBulletPoint = (e) => {
    if (e.key === "Enter") {
      setReasoning(reasoning + "• ")
    }
  }

  const showBottomText = () => {
    return (
      <>
        <span>Accepting this request will update the status to </span>
        <span className="highlight-green">COMPLETE</span>
        <span>.</span>
      </>
    )
  }

  const showCancelledMessage = () => {
    return (
      <>
        <span>Are you sure you'd like to cancel request </span>
        <span className="highlight-blue">{referenceWRT.documentNumber}</span>
        <span>? If so, please provide your reason(s) for cancelling below:</span>
      </>
    )
  }

  const showMainMessage = () => {
    return !referenceWRT.isLocal ? (
      <span>
        Please provide the shipping information below for request <span className="highlight-blue">{referenceWRT.documentNumber}</span>. If{" "}
        <b>Prepaid</b>, the <b>TAC</b> <i>or</i> the <b>SLIN</b> must have a value:
      </span>
    ) : (
      <span>
        Please provide any relevant notes below for request <span className="highlight-blue">{referenceWRT.documentNumber}</span>:
      </span>
    )
  }

  const showFirstRow = () => {
    return (
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={7}
        >
          <TextField
            sx={{ width: "100%", mb: 1, mt: 1 }}
            name="trackingNumber"
            id="trackingNumber"
            label="Tracking Number"
            InputLabelProps={{ shrink: true }}
            type="text"
            value={referenceWRT.trackingNumber}
            required
            onChange={handleChange}
          />
        </Grid>

        <Grid
          item
          xs={4.75}
        >
          <TextField
            sx={{ width: "100%", mb: 1, mt: 1 }}
            name="carrierInformation"
            id="carrierInformation"
            label="Carrier Information"
            InputLabelProps={{ shrink: true }}
            type="text"
            required
            value={referenceWRT.carrierInformation}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    )
  }

  const showRadioButtons = () => {
    return (
      <Grid
        container
        sx={{ alignItems: "center" }}
      >
        <Grid
          item
          xs={12}
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={radioValue}
              onChange={handleRadioChange}
              sx={{ justifyContent: "flex-end" }}
            >
              <FormControlLabel
                value="prepaid"
                control={
                  <Radio
                    size="small"
                    sx={{ pr: "3px", ml: "7px" }}
                  />
                }
                label="Prepaid"
              />
              <FormControlLabel
                value="thirdParty"
                control={
                  <Radio
                    size="small"
                    sx={{ pr: "3px", pl: 0 }}
                  />
                }
                label="Third Party"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  const showSecondRow = () => {
    return (
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={3.75}
        >
          <TextField
            sx={{ width: "100%", mb: 1, mt: 1 }}
            name="transportationAccountCode"
            id="transportationAccountCode"
            label="TAC"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: handleValidTACOrSLIN("TAC", referenceWRT.transportationAccountCode) ? (
                <Tooltip
                  title={
                    <Typography
                      fontSize={16}
                      fontStyle="italic"
                      textAlign="left"
                    >
                      TAC must be four characters with only uppercase letters and digits.
                    </Typography>
                  }
                  arrow
                  placement="right"
                >
                  <InputAdornment position="end">
                    <IconButton color="error">
                      <ErrorIcon />
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ) : null,
            }}
            type="text"
            disabled={!isPrepaid}
            required={isPrepaid}
            value={referenceWRT.transportationAccountCode}
            onChange={handleChange}
            error={handleValidTACOrSLIN("TAC", referenceWRT.transportationAccountCode)}
          />
        </Grid>

        <Grid
          item
          xs={3.75}
        >
          <TextField
            sx={{ width: "100%", mb: 1, mt: 1 }}
            name="sublineItemNumber"
            id="sublineItemNumber"
            label="SLIN"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: handleValidTACOrSLIN("SLIN", referenceWRT.sublineItemNumber) ? (
                <Tooltip
                  title={
                    <Typography
                      fontSize={16}
                      fontStyle="italic"
                      textAlign="left"
                    >
                      SLIN must be 6 digits.
                    </Typography>
                  }
                  arrow
                  placement="right"
                >
                  <InputAdornment position="end">
                    <IconButton color="error">
                      <ErrorIcon />
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ) : null,
            }}
            type="text"
            disabled={!isPrepaid}
            required={isPrepaid}
            value={referenceWRT.sublineItemNumber}
            onChange={handleChange}
            error={handleValidTACOrSLIN("SLIN", referenceWRT.sublineItemNumber)}
          />
        </Grid>

        <Grid
          item
          xs={3.75}
        >
          <TextField
            sx={{ width: "100%", mb: 1, mt: 1 }}
            name="shippingCost"
            id="shippingCost"
            label="Shipping Cost"
            placeholder="0.00"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            type="text"
            value={!isPrepaid ? "0.00" : referenceWRT.shippingCost}
            onChange={handleChange}
            error={isPrepaid && handleValidMonetaryValue(referenceWRT.shippingCost)}
            disabled={!isPrepaid}
            required={isPrepaid}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="wrt-confirmation-dialog-title"
        fontFamily="Merriweather"
      >
        {isCancelled ? "Cancel Warehouse Request" : determineHeader}
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleConfirmClick(false, "")}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>{isCancelled ? showCancelledMessage() : showMainMessage()}</Typography>

        {/* CANCEL REASONING */}
        {isCancelled && (
          <Grid
            item
            xs={12}
          >
            <FormControl sx={{ minWidth: "100%", minHeight: "50%" }}>
              <TextField
                name="cancelReason"
                id="cancelReason"
                type="text"
                value={reasoning}
                InputLabelProps={{ shrink: true }}
                size="small"
                multiline={true}
                rows={8}
                onChange={(e) => setReasoning(e.target.value)}
                onKeyUp={addBulletPoint}
                onFocus={() => setReasoning("• ")} // Add so first line is bulleted.
                helperText={
                  <FormHelperText sx={{ width: "100%", textAlign: "right" }}>
                    {reasoning.length} characters (minimum {minCharacterLimit} characters)
                  </FormHelperText>
                }
              />
            </FormControl>
          </Grid>
        )}

        {/* SHIPMENT/LOCAL DELIVERY */}
        {isCancelled ? null : !referenceWRT.isLocal ? (
          <>
            {/* TRACKING NUMBER AND CARRIER INFO */}
            {showFirstRow()}

            {showRadioButtons()}

            {/* TAC, SLIN, AND SHIPPING COST */}
            {showSecondRow()}
          </>
        ) : (
          <Grid
            item
            xs={12}
          >
            <TextField
              sx={{ width: "100%", mt: 1 }}
              name="notes"
              id="notes"
              label="Notes"
              InputLabelProps={{ shrink: true }}
              type="text"
              value={reasoning}
              size="small"
              multiline={true}
              rows={8}
              onChange={(e) => setReasoning(e.target.value)}
              onKeyUp={addBulletPoint}
              onFocus={() => setReasoning("• ")}
            />
          </Grid>
        )}

        {/* BOTTOM TEXT */}
        <Grid
          item
          xs={12}
          sx={{ textAlign: "center", paddingTop: "10px !important" }}
        >
          {!isCancelled ? showBottomText() : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={isCancelled ? "error" : "primary"}
          disabled={isCancelled ? reasoning.length < minCharacterLimit : determineButtonDisabled}
          onClick={() => handleConfirmClick(true, reasoning)}
        >
          {isCancelled ? "Confirm" : "Accept"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import clsx from "clsx"

import { formatEnum, getWarehouseBuildingNumber, getFormattedDate, formatUTCDate } from "../utils"
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro"

let today = new Date()
let todayPlus7 = new Date(new Date().setDate(today.getDate() + 7)).toISOString()
let todayPlus30 = new Date(new Date().setDate(today.getDate() + 30)).toISOString()

// Track UOM enums with numbers in them.
export const numberInUOM = {
  FIFTY_NINE: "59",
  SIXTY: "60",
  B_SEVEN: "B7",
  Y_FOUR: "Y4",
}

export const pendingActions = {
  ARCHIVE: 0,
  DELETE: 1,
  EDIT: 2,
}

export const transactionTypes = ["ACCPT", "ADD", "ADJST", "ARCH", "CANC", "DEL", "EDIT", "INREQ", "INTRANSIT", "ISSD", "RECD", "REV", "TRANS"]

export const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "VI",
  "WA",
  "WV",
  "WI",
  "WY",
]

const getConditionCodeClassName = (params) => {
  if (params.value == null) {
    return ""
  }
  return clsx("condition-code", {
    abg: params.value === "A",
    dbg: params.value === "D",
    fbg: params.value === "F",
    gbg: params.value === "G",
    hbg: params.value === "H",
  })
}

const getCosisDueClassName = (params) => {
  if (params.value == null) {
    return ""
  }
  return clsx("cosis-due-date", {
    // Red
    lessThan: getFormattedDate(params.value.toISOString()) < getFormattedDate(today.toISOString()),
    // Orange
    btwnTodayAndPlus7:
      getFormattedDate(params.value.toISOString()) > getFormattedDate(today.toISOString()) &&
      getFormattedDate(params.value.toISOString()) < getFormattedDate(todayPlus7),
    // Yellow
    btwnPlus7AndPlus30:
      getFormattedDate(params.value.toISOString()) > getFormattedDate(todayPlus7) &&
      getFormattedDate(params.value.toISOString()) < getFormattedDate(todayPlus30),
  })
}

export const context = {
  appTitle: "AWIMS",
  inventory_records: {
    initialState: {
      pinnedColumns: {
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "actions"],
      },
      columns: {
        columnVisibilityModel: {
          unitOfIssue: false,
          dateShipped: false,
          dateReceived: false,
          comments: false,
          sourceOfSupply: false,
          automaticReturnItemList: false,
          accountingRequirementsCode: false,
          controlledInventoryItemCode: false,
          criticalityCode: false,
          demilitarizationCode: false,
          hazardousMaterielIndicatorCode: false,
          recoverabilityCode: false,
          specialControlItemCode: false,
          supplyCategoriesOfMaterielCode: false,
          specialRequirementsCode: false,
          materielCategoryStructureFourAndFive: false,
          exchangePrice: false,
          hazardousCharacteristicsCode: false,
          typeOfStorageArmy: false,
          controlledInventoryItemCodeAmdf: false,
          electrostaticDischargeCode: false,
          uniqueItemIdentifier: false,
        },
      },
    },
    reportDefs: {
      inventory_completion_report: [
        { field: "contractNumber", headerName: "Contract #", flex: 1, minWidth: 130 },
        { field: "contractorAssetIdentificationNumber", headerName: "Contractor Asset Identification Number", minWidth: 130 },
        { field: "agencyOrServiceAssetIdentificationNumber", headerName: "Agency or Service Asset Identification Number", minWidth: 130 },
        {
          field: "itemDescription",
          headerName: "Item Description",
          flex: 2,
          minWidth: 200,
          valueGetter: (params) => {
            return params.row.nomenclature
          },
        },
        { field: "partNumber", headerName: "Manufacturer Part Number", minWidth: 175 },
        { field: "manufacturerModelNumber", headerName: "Manufacturer Model #", minWidth: 175 },
        { field: "serialNumber", headerName: "Manufacturer Serial Number", minWidth: 175 },
        { field: "materialControlNumber", headerName: "Material Control # (MCN)", minWidth: 130 },
        { field: "nationalStockNumber", headerName: "National Stock # (NSN)", minWidth: 175 },
        {
          field: "quantity",
          headerName: "Qty",
          type: "number",
          minWidth: 100,
          valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
          valueFormatter: ({ value }) =>
            value != null
              ? Intl.NumberFormat("en-US", {
                  maximumFractionsDigits: 3,
                  minimumFractionDigits: 0,
                }).format(value)
              : "",
        },
        {
          field: "unitOfMeasurement",
          headerName: "Unit Of Measure",
          minWidth: 125,
          valueGetter: (params) => {
            return params.value in numberInUOM ? numberInUOM[params.value] : params.value
          },
        },
        {
          field: "unitPrice",
          headerName: "Unit Price",
          type: "number",
          minWidth: 175,
          valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
          valueFormatter: ({ value }) =>
            value != null
              ? "$ " +
                Intl.NumberFormat("en-US", {
                  maximumFractionsDigits: 2,
                  minimumFractionDigits: 2,
                }).format(value)
              : "",
        },
        { field: "uniqueItemIdentifier", headerName: "Unique Item Identifier", minWidth: 130 },
        {
          field: "site",
          headerName: "Location",
          minWidth: 120,
          valueGetter: (params) => {
            return getWarehouseBuildingNumber(params.row.managementRecordWarehouseId, context.warehouseData)
          },
        },
        { field: "location", headerName: "Sub Location", minWidth: 120 },
        {
          field: "typeOfGovernmentProperty",
          headerName: "Type of Government Property",
          minWidth: 175,
          valueGetter: () => {
            return "Government-Owned Materiel"
          },
        },
        {
          field: "classificationOfGovernmentProperty",
          headerName: "Classification of Government Property",
          minWidth: 175,
          valueGetter: () => {
            return "Materiel"
          },
        },
        {
          field: "inventoryDate",
          type: "date",
          headerName: "Date of Last Physical Inventory",
          valueFormatter: (params) => {
            return formatUTCDate(params.value, false)
          },
          valueGetter: ({ value }) => {
            return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
          },
          minWidth: 175,
        },
        {
          field: "conditionCode",
          headerName: "Condition Code",
          minWidth: 175,
          cellClassName: (params) => {
            return getConditionCodeClassName(params)
          },
        },
      ],
      COSIS_completion_report: [
        {
          field: "cosisDueDate",
          headerName: "COSIS Due",
          description: "COSIS Due",
          type: "date",
          minWidth: 100,
          valueFormatter: (params) => {
            return formatUTCDate(params.value, false)
          },
          cellClassName: (params) => {
            return getCosisDueClassName(params)
          },
          valueGetter: ({ value }) => {
            return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
          },
        },
        {
          field: "cosisCompleteDate",
          headerName: "COSIS Complete",
          type: "date",
          valueFormatter: (params) => {
            return formatUTCDate(params.value, false)
          },
          valueGetter: ({ value }) => {
            return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
          },
          minWidth: 125,
        },
        { field: "packagingDeficiency", headerName: "Packaging Deficiency", minWidth: 165 },
        { field: "cosisProcedures", headerName: "COSIS Procedures", minWidth: 150 },
        {
          field: "nationalStockNumber",
          headerName: "NSN",
          minWidth: 130,
          valueFormatter: (params) => {
            let row = params.api.getRow(params.id)
            return !params.value && row.federalSupplyClassification && row.nationalItemIdentificationNumber
              ? row.federalSupplyClassification + row.nationalItemIdentificationNumber
              : ""
          },
        },
        { field: "partNumber", headerName: "Part #", minWidth: 100 },
        { field: "manp", headerName: "MANP", minWidth: 110 },
        { field: "materialControlNumber", headerName: "MCN", minWidth: 150 },
        { field: "serialNumber", headerName: "Serial #", minWidth: 100 },
        { field: "nomenclature", headerName: "Nomenclature", flex: 2, minWidth: 230 },
        {
          field: "quantity",
          headerName: "Qty",
          type: "number",
          minWidth: 80,
          valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
          valueFormatter: ({ value }) =>
            value != null
              ? Intl.NumberFormat("en-US", {
                  maximumFractionsDigits: 3,
                  minimumFractionDigits: 0,
                }).format(value)
              : "",
        },
        {
          field: "conditionCode",
          headerName: "Condition Code",
          minWidth: 120,
          cellClassName: (params) => {
            return getConditionCodeClassName(params)
          },
        },
        { field: "location", headerName: "Location", minWidth: 100 },
        { field: "federalSupplyClassification", headerName: "FSC", minWidth: 100 },
        { field: "nationalItemIdentificationNumber", headerName: "NIIN", minWidth: 100 },
        { field: "shelfLifeCode", headerName: "SLC", minWidth: 100 },
        { field: "typeOfStorageArmy", headerName: "TOS ARMY", minWidth: 100 },
        { field: "electrostaticDischargeCode", headerName: "ESD", minWidth: 100 },
      ],
      COSIS_requirements_report: [
        {
          field: "cosisDueDate",
          headerName: "COSIS Due",
          description: "COSIS Due",
          type: "date",
          minWidth: 100,
          valueGetter: ({ value }) => {
            return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
          },
          cellClassName: (params) => {
            return getCosisDueClassName(params)
          },
          valueFormatter: (params) => {
            return formatUTCDate(params.value, false)
          },
        },
        {
          field: "cosisCompleteDate",
          headerName: "COSIS Complete",
          type: "date",
          minWidth: 125,
          valueFormatter: (params) => {
            return formatUTCDate(params.value, false)
          },
          valueGetter: ({ value }) => {
            return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
          },
        },
        {
          field: "packagingDeficiency",
          headerName: "Packaging Deficiency",
          minWidth: 165,
          renderCell: (params) => {
            if (params.value) {
              params.row.packagingDeficiency = ""
              return <>{params.value}</>
            }
          },
        },
        { field: "cosisProcedures", headerName: "COSIS Procedures", minWidth: 150 },
        {
          field: "nationalStockNumber",
          headerName: "NSN",
          minWidth: 130,
          valueFormatter: (params) => {
            let row = params.api.getRow(params.id)
            return !params.value && row.federalSupplyClassification && row.nationalItemIdentificationNumber
              ? row.federalSupplyClassification + row.nationalItemIdentificationNumber
              : ""
          },
        },
        { field: "partNumber", headerName: "Part #", minWidth: 100 },
        { field: "manp", headerName: "MANP", minWidth: 110 },
        { field: "materialControlNumber", headerName: "MCN", minWidth: 150 },
        { field: "serialNumber", headerName: "Serial #", minWidth: 100 },
        { field: "nomenclature", headerName: "Nomenclature", flex: 2, minWidth: 230 },
        {
          field: "quantity",
          type: "number",
          headerName: "Qty",
          minWidth: 80,
          valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
          valueFormatter: ({ value }) =>
            value != null
              ? Intl.NumberFormat("en-US", {
                  maximumFractionsDigits: 3,
                  minimumFractionDigits: 0,
                }).format(value)
              : "",
        },
        {
          field: "conditionCode",
          headerName: "Condition Code",
          minWidth: 120,
          cellClassName: (params) => {
            return getConditionCodeClassName(params)
          },
        },
        { field: "location", headerName: "Location", minWidth: 100 },
        { field: "federalSupplyClassification", headerName: "FSC", minWidth: 100 },
        { field: "nationalItemIdentificationNumber", headerName: "NIIN", minWidth: 100 },
        { field: "shelfLifeCode", headerName: "SLC", minWidth: 60 },
        { field: "controlledInventoryItemCodeAmdf", headerName: "CIIC AMDF", minWidth: 100 },
        { field: "methodOfPreservationArmy", headerName: "ARMY MOP", minWidth: 100 },
        { field: "electrostaticDischargeCode", headerName: "ESD", minWidth: 100 },
      ],
      monthly_percentage_report: [
        { field: "nomenclature", headerName: "Nomenclature", minWidth: 230 },
        { field: "federalSupplyClassification", headerName: "FSC", minWidth: 30 },
        { field: "nationalItemIdentificationNumber", headerName: "NIIN", minWidth: 100 },
        { field: "partNumber", headerName: "Part Number", minWidth: 130 },
        { field: "serialNumber", headerName: "Serial Number", minWidth: 50 },
        {
          field: "quantity",
          headerName: "Qty",
          type: "number",
          minWidth: 90,
          valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
          valueFormatter: ({ value }) =>
            value != null
              ? Intl.NumberFormat("en-US", {
                  maximumFractionsDigits: 3,
                  minimumFractionDigits: 0,
                }).format(value)
              : "",
        },
        {
          field: "unitOfMeasurement",
          headerName: "UOM",
          minWidth: 30,
          valueGetter: (params) => {
            return params.value in numberInUOM ? numberInUOM[params.value] : params.value
          },
        },
        {
          field: "site",
          headerName: "Site",
          minWidth: 120,
          valueGetter: (params) => {
            return getWarehouseBuildingNumber(params.row.managementRecordWarehouseId, context.warehouseData)
          },
        },
        { field: "location", headerName: "Location", minWidth: 150 },
        { field: "propertyNumber", headerName: "Property Number", minWidth: 130 },
        { field: "radioFrequencyIdentificationNumber", headerName: "RFID", minWidth: 180 },
        { field: "commercialAndGovernmentEntityCode", headerName: "CAGE Code", minWidth: 100 },
        { field: "shelfLifeCode", headerName: "SLC", minWidth: 30 },
        {
          field: "shelfLifeExpirationDate",
          headerName: "SL EXP Date",
          type: "date",
          valueFormatter: (params) => {
            return formatUTCDate(params.value, false)
          },
          valueGetter: ({ value }) => value && new Date(value),
          minWidth: 100,
        },
        { field: "methodOfPreservationArmy", headerName: "ARMY MOP", minWidth: 100 },
        { field: "conditionCode", headerName: "Condition Code", minWidth: 100 },
      ],
      TIS_format: [
        { field: "nomenclature", headerName: "Nomenclature", minWidth: 230 },
        { field: "federalSupplyClassification", headerName: "FSC", minWidth: 30 },
        { field: "nationalItemIdentificationNumber", headerName: "NIIN", minWidth: 100 },
        { field: "partNumber", headerName: "Part Number", minWidth: 130 },
        { field: "serialNumber", headerName: "Serial Number", minWidth: 50 },
        {
          field: "quantity",
          headerName: "Qty",
          type: "number",
          minWidth: 90,
          valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
          valueFormatter: ({ value }) =>
            value != null
              ? Intl.NumberFormat("en-US", {
                  maximumFractionsDigits: 3,
                  minimumFractionDigits: 0,
                }).format(value)
              : "",
        },
        {
          field: "unitOfMeasurement",
          headerName: "UOM",
          minWidth: 30,
          valueGetter: (params) => {
            return params.value in numberInUOM ? numberInUOM[params.value] : params.value
          },
        },
        {
          field: "site",
          headerName: "Site",
          minWidth: 120,
          valueGetter: (params) => {
            return getWarehouseBuildingNumber(params.row.managementRecordWarehouseId, context.warehouseData)
          },
        },
        { field: "location", headerName: "Location", minWidth: 150 },
        { field: "propertyNumber", headerName: "Property Number", minWidth: 130 },
        { field: "radioFrequencyIdentificationNumber", headerName: "RFID", minWidth: 180 },
        { field: "commercialAndGovernmentEntityCode", headerName: "CAGE Code", minWidth: 100 },
        { field: "shelfLifeCode", headerName: "SLC", minWidth: 30 },
        {
          field: "shelfLifeExpirationDate",
          headerName: "SL EXP Date",
          type: "date",
          valueFormatter: (params) => {
            return formatUTCDate(params.value, false)
          },
          valueGetter: ({ value }) => value && new Date(value),
          minWidth: 100,
        },
        { field: "methodOfPreservationArmy", headerName: "ARMY MOP", minWidth: 100 },
        { field: "conditionCode", headerName: "Condition Code", minWidth: 100 },
      ],
    },
    columns: [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 80,
        sortable: false,
        filterable: false,
        hideable: false,
        disableExport: true,
      },
      { field: "nationalStockNumber", headerName: "NSN", minWidth: 90 },
      { field: "partNumber", headerName: "Part #", minWidth: 110 },
      { field: "manp", headerName: "MANP", minWidth: 110 },
      { field: "materialControlNumber", headerName: "MCN", minWidth: 150 },
      { field: "serialNumber", headerName: "Serial #", minWidth: 100 },
      { field: "nomenclature", headerName: "Nomenclature", minWidth: 300 },
      // {
      //   field: "quantity",
      //   headerName: "Qty",
      //   type: "number",
      //   minWidth: 50,
      //   valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
      //   valueFormatter: ({ value }) =>
      //     value != null
      //       ? Intl.NumberFormat("en-US", {
      //           maximumFractionsDigits: 3,
      //           minimumFractionDigits: 0,
      //         }).format(value)
      //       : "",
      // },
      {
        field: "currentBalance",
        headerName: "Current Balance",
        type: "number",
        minWidth: 130,
        valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
        valueFormatter: ({ value }) =>
          value != null
            ? Intl.NumberFormat("en-US", {
                maximumFractionsDigits: 3,
                minimumFractionDigits: 0,
              }).format(value)
            : "",
      },
      {
        field: "pendingBalance",
        headerName: "Pending Balance",
        type: "number",
        minWidth: 130,
        valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
        valueFormatter: ({ value }) =>
          value != null
            ? Intl.NumberFormat("en-US", {
                maximumFractionsDigits: 3,
                minimumFractionDigits: 0,
              }).format(value)
            : "",
      },
      {
        field: "unitOfMeasurement",
        headerName: "UOM",
        minWidth: 30,
        valueGetter: (params) => {
          return params.value in numberInUOM ? numberInUOM[params.value] : params.value
        },
      },
      { field: "unitOfIssue", headerName: "UI", minWidth: 50 },
      {
        field: "unitPrice",
        headerName: "Unit Price",
        type: "number",
        minWidth: 80,
        valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
        valueFormatter: ({ value }) =>
          value != null
            ? "$ " +
              Intl.NumberFormat("en-US", {
                maximumFractionsDigits: 2,
                minimumFractionDigits: 2,
              }).format(value)
            : "",
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        type: "number",
        minWidth: 80,
        valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
        valueFormatter: ({ value }) =>
          value != null
            ? "$ " +
              Intl.NumberFormat("en-US", {
                maximumFractionsDigits: 2,
                minimumFractionDigits: 2,
              }).format(value)
            : "",
      },
      {
        field: "conditionCode",
        headerName: "Condition Code",
        minWidth: 120,
        cellClassName: (params) => {
          return getConditionCodeClassName(params)
        },
      },
      {
        field: "site",
        headerName: "Site",
        minWidth: 100,
        valueGetter: (params) => {
          return getWarehouseBuildingNumber(params.row.managementRecordWarehouseId, context.warehouseData)
        },
      },
      { field: "location", headerName: "Location", minWidth: 80 },
      { field: "federalSupplyClassification", headerName: "FSC", minWidth: 60 },
      { field: "nationalItemIdentificationNumber", headerName: "NIIN", minWidth: 100 },
      { field: "uniqueItemIdentifier", headerName: "UII", minWidth: 80 },
      { field: "armyEnterpriseSystemIntegrationProgramSerializationIndicator", headerName: "AESIP Serialization Indicator", flex: 2, minWidth: 210 },
      { field: "assetIdentification", headerName: "Asset ID", minWidth: 150 },
      { field: "commercialAndGovernmentEntityCode", headerName: "CAGE Code", minWidth: 100 },
      { field: "shelfLifeCode", headerName: "SLC", minWidth: 60 },
      { field: "propertyNumber", headerName: "Property #", minWidth: 100 },
      { field: "radioFrequencyIdentificationNumber", headerName: "RFID", minWidth: 150 },
      {
        field: "dateReceived",
        headerName: "Date Received",
        type: "date",
        minWidth: 110,
        valueFormatter: (params) => {
          return formatUTCDate(params.value, false)
        },
        valueGetter: ({ value }) => {
          return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
        },
      },
      {
        field: "dateShipped",
        headerName: "Date Shipped",
        type: "date",
        minWidth: 110,
        valueFormatter: (params) => {
          return formatUTCDate(params.value, false)
        },
        valueGetter: ({ value }) => {
          return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
        },
      },
      {
        field: "shelfLifeExpirationDate",
        headerName: "SL EXP Date",
        type: "date",
        valueFormatter: (params) => {
          return formatUTCDate(params.value, false)
        },
        valueGetter: ({ value }) => {
          return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
        },
        minWidth: 100,
      },

      { field: "sourceOfSupply", headerName: "SOS", minWidth: 60 },
      { field: "automaticReturnItemList", headerName: "ARIL", minWidth: 60 },
      { field: "accountingRequirementsCode", headerName: "ARC", minWidth: 60 },
      { field: "controlledInventoryItemCode", headerName: "CIIC", minWidth: 60 },
      { field: "criticalityCode", headerName: "CRITL Code", minWidth: 100 },
      { field: "demilitarizationCode", headerName: "DEMIL Code", minWidth: 100 },
      { field: "hazardousMaterielIndicatorCode", headerName: "HMIC", minWidth: 70 },
      { field: "recoverabilityCode", headerName: "Recoverability Code", minWidth: 70 },
      { field: "specialControlItemCode", headerName: "SCIC", minWidth: 60 },
      { field: "supplyCategoriesOfMaterielCode", headerName: "SCMC", minWidth: 60 },
      { field: "specialRequirementsCode", headerName: "SRC", minWidth: 60 },
      { field: "materielCategoryStructureFourAndFive", headerName: "MATCAT 4 & 5", minWidth: 115 },
      {
        field: "exchangePrice",
        headerName: "Exchange Price",
        minWidth: 120,
        valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
        valueFormatter: ({ value }) =>
          value != null
            ? "$ " +
              Intl.NumberFormat("en-US", {
                maximumFractionsDigits: 2,
                minimumFractionDigits: 2,
              }).format(value)
            : "",
      },
      { field: "hazardousCharacteristicsCode", headerName: "HCC", minWidth: 50 },
      { field: "controlledInventoryItemCodeAmdf", headerName: "CIIC AMDF", minWidth: 100 },
      { field: "typeOfStorageArmy", headerName: "TOS ARMY", minWidth: 100 },
      { field: "methodOfPreservationArmy", headerName: "ARMY MOP", minWidth: 100 },
      { field: "electrostaticDischargeCode", headerName: "ESD", minWidth: 50 },

      { field: "comments", headerName: "Comments", minWidth: 100 },
      {
        field: "inventoryDate",
        headerName: "Inventory Date",
        type: "date",
        valueFormatter: (params) => {
          return formatUTCDate(params.value, false)
        },
        valueGetter: ({ value }) => value && new Date(value),
        minWidth: 150,
      },
      {
        field: "cosisDueDate",
        headerName: "COSIS Due",
        description: "COSIS Due",
        minWidth: 100,
        type: "date",
        valueFormatter: (params) => {
          return formatUTCDate(params.value, false)
        },
        cellClassName: (params) => {
          return getCosisDueClassName(params)
        },
        valueGetter: ({ value }) => {
          return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
        },
      },
      {
        field: "cosisCompleteDate",
        headerName: "COSIS Complete",
        type: "date",
        valueFormatter: (params) => {
          return formatUTCDate(params.value, false)
        },
        valueGetter: ({ value }) => {
          return value && new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
        },
        minWidth: 125,
      },
      { field: "packagingDeficiency", headerName: "Packaging Deficiency", minWidth: 165 },
      { field: "cosisProcedures", headerName: "COSIS Procedures", minWidth: 150 },
    ],
    /* Manually entered fields
      Packaging Deficiency
      Quantity
      Location
      Site
      AESIP Serialization Indicator
      Date Shipped
      Date Received
      Comments
      Condition Code
      Army Program Id
      Warehouse Id    
    */
    formFields: [
      {
        field: "managementRecordArmyProgramId",
        title: "Army Program",
        type: "select",
      },
      {
        field: "managementRecordWarehouseId",
        title: "Warehouse",
        type: "select",
      },
      {
        field: "location",
        title: "Location",
        type: "text",
      },
      {
        field: "armyEnterpriseSystemIntegrationProgramSerializationIndicator",
        title: "AESIP Serialization Indicator",
        type: "text",
      },
      {
        field: "assetIdentification",
        title: "Asset ID",
        type: "text",
      },
      {
        field: "nationalStockNumber",
        title: "NSN",
        type: "text",
      },
      {
        field: "nationalItemIdentificationNumber",
        title: "NIIN",
        type: "text",
      },
      {
        field: "uniqueItemIdentifier",
        title: "UII",
        type: "text",
      },
      {
        field: "partNumber",
        title: "Part #",
        type: "text",
      },
      {
        field: "manp",
        title: "MANP",
        type: "text",
      },
      {
        field: "materialControlNumber",
        title: "MCN",
        type: "text",
      },
      {
        field: "serialNumber",
        title: "Serial #",
        type: "expand",
      },
      {
        field: "nomenclature",
        title: "Nomenclature",
        type: "text",
      },
      {
        field: "quantity",
        title: "Qty",
        type: "text",
      },
      {
        field: "unitOfMeasurement",
        title: "UOM",
        type: "select",
        options: {
          FIFTY_NINE: 0,
          SIXTY: 1,
          AR: 2,
          AV: 3,
          B_SEVEN: 4,
          BF: 5,
          BO: 88,
          BQ: 6,
          BX: 83,
          CA: 85,
          CC: 7,
          CD: 8,
          CF: 9,
          CG: 10,
          CI: 11,
          CM: 12,
          CU: 13,
          CZ: 14,
          DC: 15,
          DE: 16,
          DG: 17,
          DL: 18,
          DM: 19,
          DW: 20,
          DZ: 21,
          EA: 22,
          EX: 23,
          FD: 24,
          FG: 25,
          FO: 26,
          FR: 27,
          FT: 28,
          GG: 29,
          GI: 30,
          GL: 31,
          GM: 32,
          GN: 33,
          GR: 34,
          HD: 35,
          HF: 36,
          HP: 37,
          HS: 38,
          HW: 39,
          HY: 40,
          IN: 41,
          KG: 42,
          KM: 43,
          KR: 44,
          KT: 45,
          LB: 46,
          LF: 47,
          LI: 48,
          MC: 49,
          MF: 50,
          MG: 51,
          MI: 52,
          ML: 53,
          MM: 54,
          MR: 55,
          MX: 56,
          OZ: 57,
          PI: 58,
          PK: 84,
          PR: 59,
          PT: 60,
          PX: 61,
          QT: 62,
          RA: 63,
          RD: 64,
          RL: 65,
          RM: 66,
          RX: 67,
          SE: 68,
          SF: 69,
          SH: 70,
          SI: 71,
          SK: 72,
          SM: 73,
          SO: 74,
          SQ: 75,
          ST: 86,
          SY: 76,
          SZ: 77,
          TN: 78,
          TO: 79,
          TT: 80,
          US: 81,
          Y_FOUR: 87,
          YD: 82,
        }, // Last value is currently at 88 for UOM.
      },
      {
        field: "unitPrice",
        title: "Unit Price",
        type: "text",
      },
      {
        field: "totalCost",
        title: "Total Cost",
        type: "text",
      },
      {
        field: "unitOfIssue",
        title: "UI",
        type: "select",
        options: {
          AM: 0,
          AT: 1,
          AY: 2,
          BA: 3,
          BD: 4,
          BE: 5,
          BF: 6,
          BG: 7,
          BK: 8,
          BL: 9,
          BO: 10,
          BR: 11,
          BT: 12,
          BX: 13,
          CA: 14,
          CB: 15,
          CD: 16,
          CE: 17,
          CF: 18,
          CK: 19,
          CL: 20,
          CM: 21,
          CN: 22,
          CO: 23,
          CS: 24,
          CY: 25,
          CZ: 26,
          DR: 27,
          DZ: 28,
          EA: 29,
          FT: 30,
          FV: 31,
          FY: 32,
          GL: 33,
          GP: 34,
          GR: 35,
          HD: 36,
          HK: 37,
          IN: 38,
          JR: 39,
          K7: 40,
          KG: 41,
          KT: 42,
          KZ: 43,
          LB: 44,
          LG: 45,
          LI: 46,
          LT: 47,
          MC: 48,
          ME: 49,
          MM: 50,
          MR: 51,
          MX: 52,
          OT: 53,
          OZ: 54,
          PD: 55,
          PG: 56,
          PL: 57,
          PM: 58,
          PR: 59,
          PT: 60,
          PZ: 61,
          QT: 62,
          RA: 63,
          RL: 64,
          RM: 65,
          RO: 66,
          SD: 67,
          SE: 68,
          SF: 69,
          SH: 70,
          SI: 71,
          SK: 72,
          SL: 73,
          SO: 74,
          SP: 75,
          SV: 76,
          SX: 77,
          SY: 78,
          T9: 79,
          TD: 80,
          TE: 81,
          TF: 82,
          TN: 83,
          TO: 84,
          TS: 85,
          TU: 86,
          VI: 87,
          YD: 88,
          YS: 89,
          ZF: 90,
        },
      },
      {
        field: "conditionCode",
        title: "Condition Code",
        type: "select",
        options: {
          A: 0,
          B: 1,
          C: 2,
          D: 3,
          E: 4,
          F: 5,
          G: 6,
          H: 7,
          J: 8,
          K: 9,
          Q: 10,
          S: 11,
          R: 12,
        },
      },
      {
        field: "federalSupplyClassification",
        title: "FSC",
        type: "text",
      },
      {
        field: "commercialAndGovernmentEntityCode",
        title: "CAGE Code",
        type: "text",
      },
      {
        field: "exchangePrice",
        title: "Exchange Price",
        type: "text",
      },
      {
        field: "dateShipped",
        title: "Date Shipped",
        type: "date",
      },
      {
        field: "dateReceived",
        title: "Date Received",
        type: "date",
      },
      {
        field: "packagingDeficiency",
        title: "Packaging Deficiency",
        type: "select",
      },
      {
        field: "cosisDueDate",
        title: "COSIS Due Date",
        type: "date",
      },
      {
        field: "cosisCompleteDate",
        title: "COSIS Completion Date",
        type: "date",
      },
      {
        field: "shelfLifeExpirationDate",
        title: "SL EXP Date",
        type: "date",
      },

      // FEDLOG/COSIS FIELDS
      {
        field: "accountingRequirementsCode",
        title: "ARC",
        type: "select",
        options: {
          D: 0,
          N: 1,
          X: 2,
        },
      },
      {
        field: "automaticReturnItemList",
        title: "ARIL",
        type: "select",
        options: {
          C: 0,
          D: 1,
          E: 2,
          M: 3,
          N: 4,
          R: 5,
          S: 6,
        },
      },
      {
        field: "controlledInventoryItemCode",
        title: "CIIC",
        type: "text",
      },
      {
        field: "criticalityCode",
        title: "CRITL Code",
        type: "select",
        options: {
          C: 0,
          E: 1,
          F: 2,
          H: 3,
          M: 4,
          N: 5,
          S: 6,
          V: 7,
          X: 8,
          Y: 9,
        },
      },
      {
        field: "demilitarizationCode",
        title: "DEMIL Code",
        type: "select",
        options: {
          A: 0,
          B: 1,
          C: 2,
          D: 3,
          E: 4,
          F: 5,
          G: 6,
          P: 7,
          Q: 8,
        },
      },
      {
        field: "electrostaticDischargeCode",
        title: "ESD",
        type: "select",
        options: {
          A: 0,
          B: 1,
          C: 2,
          D: 3,
        },
      },
      {
        field: "hazardousCharacteristicsCode",
        title: "HCC",
        type: "text",
      },
      {
        field: "hazardousMaterielIndicatorCode",
        title: "HMIC",
        type: "select",
        options: {
          D: 0,
          N: 1,
          P: 2,
          Y: 3,
        },
      },
      {
        field: "recoverabilityCode",
        title: "Recoverability Code",
        type: "select",
        options: {
          A: 0,
          B: 1,
          C: 2,
          D: 3,
          E: 4,
          F: 5,
          G: 6,
          H: 7,
          I: 8,
          J: 9,
          K: 10,
          L: 11,
          M: 12,
          N: 13,
          O: 14,
          P: 15,
          Q: 16,
          R: 17,
          S: 18,
          T: 19,
          U: 20,
          V: 21,
          W: 22,
          X: 23,
          Y: 24,
          Z: 25,
        },
      },
      {
        field: "materielCategoryStructureFourAndFive",
        title: "MATCAT 4 & 5",
        type: "text",
      },
      {
        field: "shelfLifeCode",
        title: "SLC",
        type: "text",
      },
      {
        field: "sourceOfSupply",
        title: "SOS",
        type: "text",
      },
      {
        field: "specialControlItemCode",
        title: "SCIC",
        type: "text",
      },
      {
        field: "specialRequirementsCode",
        title: "SRC",
        type: "text",
      },
      {
        field: "supplyCategoriesOfMaterielCode",
        title: "SCMC",
        type: "text",
      },
      {
        field: "controlledInventoryItemCodeAmdf",
        title: "CIIC AMDF",
        type: "text",
      },
      {
        field: "methodOfPreservationArmy",
        title: "ARMY MOP",
        type: "text",
      },
      {
        field: "typeOfStorageArmy",
        title: "TOS ARMY",
        type: "text",
      },
      {
        field: "comments",
        title: "Comments",
        type: "text",
      },
      {
        field: "documents",
        title: "Documents",
        type: "upload",
      },
    ],
  },
  warehouses: {
    columns: [
      { field: "buildingNumber", headerName: "Building Number", flex: 1, minWidth: 150 },
      { field: "description", headerName: "Description", flex: 1, minWidth: 100 },
      { field: "physicalAddress", headerName: "Physical Address", flex: 1, minWidth: 100 },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        minWidth: 80,
        renderCell: (params) => {
          if (params?.value) {
            return <>{formatEnum(params.value)}</>
          }
          return null
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        flex: 0.3,
        minWidth: 120,
        sortable: false,
        filterable: false,
        hideable: false,
        disableExport: true,
        headerAlign: "center",
      },
    ],
    formFields: [
      {
        field: "buildingNumber",
        title: "Building Number",
        type: "text",
      },
      {
        field: "description",
        title: "Description",
        type: "text",
      },
      {
        field: "physicalAddress",
        title: "Physical Address",
        type: "text",
      },
      {
        field: "type",
        title: "Type",
        type: "select",
        options: {
          PROTECTED: 0,
          ENVIRONMENTALLY_CONTROLLED: 1,
          HEAT_ONLY: 2,
          OPEN_YARD: 3,
          CONTAINER: 4,
          LAMS: 5,
          NON_ENVIRONMENTALLY_CONTROLLED: 6,
        },
      },
    ],
  },
  army_programs: {
    columns: [
      { field: "name", headerName: "Name", flex: 1, minWidth: 100 },
      { field: "fullName", headerName: "Full Name", flex: 1.5, minWidth: 200 },
      { field: "description", headerName: "Description", flex: 1, minWidth: 100 },
      //
      {
        field: "parentProgramId",
        headerName: "Parent Program",
        flex: 1,
        mindWidth: 100,
        valueFormatter: (params) => {
          let parentProgram = context["allProgramsData"].find((f) => f.id === params.value)
          return parentProgram !== undefined ? parentProgram.name : ""
        },
      },
      //
      { field: "programLead", headerName: "Program Lead", flex: 1, minWidth: 100 },
      { field: "programLeadEmail", headerName: "Program Lead Email", flex: 1, minWidth: 100 },
      { field: "programLeadPhoneNumber", headerName: "Program Lead Phone #", flex: 1, minWidth: 80 },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        flex: 0.3,
        minWidth: 120,
        sortable: false,
        filterable: false,
        hideable: false,
        disableExport: true,
        headerAlign: "center",
      },
    ],
    formFields: [
      {
        field: "name",
        title: "Name",
        type: "text",
      },
      {
        field: "fullName",
        title: "Full Name",
        type: "text",
      },
      {
        field: "description",
        title: "Description",
        type: "text",
      },
      {
        field: "parentProgramId",
        title: "Parent Program",
        type: "select",
      },
      {
        field: "programLead",
        title: "Program Lead",
        type: "text",
      },
      {
        field: "programLeadEmail",
        title: "Program Lead Email",
        type: "text",
      },
      {
        field: "programLeadPhoneNumber",
        title: "Program Lead Phone #",
        type: "text",
      },
    ],
  },
}

// ** MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Custom
import { dialogPaperStyle, dialogStyle } from "../utils"

export const FedLogSelectModal = ({ shouldOpen, handleFedLogSelect, fedlogItems }) => {
  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="md"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="fedlog-dialog-title"
        fontFamily="Merriweather"
      >
        Multiple Entries Found
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleFedLogSelect()}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>Please select the appropriate FEDLOG entry:</Typography>
        <TableContainer style={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>NIIN</TableCell>
                <TableCell align="right">FSC</TableCell>
                <TableCell align="right">Nomenclature</TableCell>
                <TableCell align="right">Part Number</TableCell>
                <TableCell align="right">CAGE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fedlogItems.map((item) => (
                <TableRow
                  key={item.item_name + item.part_number + item.cage_code}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell padding="checkbox">
                    <Radio
                      onClick={() => handleFedLogSelect(item)}
                      checked={false}
                      inputProps={{ "aria-labelledby": item.part_number }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {item.niin}
                  </TableCell>
                  <TableCell align="right">{item.fsc}</TableCell>
                  <TableCell align="right">{item.item_name}</TableCell>
                  <TableCell align="right">{item.part_number}</TableCell>
                  <TableCell align="right">{item.cage_code}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}

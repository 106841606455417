import { useEffect, useState } from "react"

// ** MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { notify, dialogStyle, dialogPaperStyle } from "../utils"

export const ConsolidateInventoryModal = ({ shouldOpen, handleClose, handleInventoryUpdate, selectedRows, clearSelectedRows }) => {
  const [value, setValue] = useState("")
  const [data, setData] = useState(false)
  const [passesTests, setPassesTests] = useState(null)

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const checkIfLikeItemsOrInProcess = async () => {
    // NOTE: If we get to this point we must assume there are more than one item but less than or equal to ten in the array.
    let compareObjects = selectedRows.map((f) => {
      return {
        nationalStockNumber: f.nationalStockNumber,
        partNumber: f.partNumber,
        serialNumber: f.serialNumber,
        programId: f.managementRecordArmyProgramId,
      }
    })

    // Check if one of the items is already in a process or does not have the same NSN and P/N.
    let mergedItems
    let itemIds
    await apiCalls.getRecords("WarehouseRequestItems").then(async (wrtItems) => {
      await apiCalls.getRecords("TransferItems").then((transferItems) => {
        mergedItems = [...wrtItems.data.value.map((f) => f.inventoryRecordId), ...transferItems.data.value.map((f) => f.inventoryRecordId)]
        itemIds = selectedRows.map((f) => f.id)
      })
    })

    let matchTest = compareObjects.every((f) => JSON.stringify(f) === JSON.stringify(compareObjects[0]))
    let inProcessTest = itemIds.some((item) => mergedItems.includes(item))
    let result

    if (matchTest) {
      if (inProcessTest) result = false
      else result = true
    } else result = false

    setPassesTests(result)
  }

  useEffect(() => {
    async function callFunction() {
      await checkIfLikeItemsOrInProcess()
    }
    callFunction()
  }, [])

  useEffect(() => {
    if (passesTests !== null) setData(true)
  }, [passesTests])

  const handleConsolidation = async () => {
    try {
      let selectedItem = selectedRows.find((f) => f.id === value)
      let totalQuantity = [...selectedRows].reduce((count, currentValue) => {
        count += currentValue.quantity
        return count
      }, 0)

      // Make changes to the Quantity and Total Cost fields for the item that's selected.
      let operation = "replace"
      let patchChanges = [
        { path: "Quantity", op: `${operation}`, value: totalQuantity },
        {
          path: "TotalCost",
          op: `${operation}`,
          value: totalQuantity * selectedItem.unitPrice,
        },
      ]

      await apiCalls.patchRecordMultiple(value, "InventoryRecords", patchChanges).then(async (response) => {
        if (response.status === 200) {
          // Delete the item(s) that weren't selected.
          await Promise.all(
            selectedRows
              .filter((f) => f.id !== value)
              .map(async (g) => {
                await apiCalls.deleteRecord(g.id, "InventoryRecords")
              })
          ).then(() => {
            notify("success", "Consolidation was successful.")
            handleClose(false)
            clearSelectedRows()
            handleInventoryUpdate()
          })
        }
      })
    } catch (error) {
      console.error("Error:", error)
      notify("error", `There was an issue consolidating the items.`)
      handleClose(false)
    }
  }

  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="consolidate-inventory-dialog-title"
        fontFamily="Merriweather"
      >
        {passesTests ? "Consolidate Item(s)" : "Unable to Consolidate"}
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleClose(false)}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {passesTests ? (
          <>
            <Typography gutterBottom>
              Select the item to consolidate to. The item(s) that aren't selected will have their quantity added to the selected item's quantity and
              then be deleted.
            </Typography>

            <Paper style={{ maxHeight: 150, boxShadow: "none", overflow: "auto" }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  {selectedRows?.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.id}
                        value={item.id}
                        control={<Radio />}
                        label={
                          <span>
                            <b>Property #: </b> {item.propertyNumber} | <b>Qty: </b> {item.quantity} | <b>Location: </b> {item.location}
                          </span>
                        }
                        sx={{ textAlign: "left" }}
                      />
                    )
                  })}
                </RadioGroup>
              </FormControl>
            </Paper>
          </>
        ) : (
          <>
            <Typography gutterBottom>
              The items to consolidate do not have a matching NSN and P/N, they are serialized, or one or more items are already in a process.
            </Typography>
            <Typography>If you're consolidating under a parent program, the items must also be from the same sub-program.</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {passesTests === true && (
          <Button
            variant="contained"
            component="label"
            onClick={handleConsolidation}
            disabled={value === ""}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

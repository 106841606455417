import * as React from "react"
import { useState, useEffect, useContext } from "react"

// ** MUI
import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import RemoveDoneIcon from "@mui/icons-material/RemoveDone"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Custom
import AppContext from "../AppContext"
import { useAuth } from "react-oidc-context"
import { dialogPaperStyle, dialogStyle, notify } from "../utils"

// Style for displaying chips.
const chipStyle = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  listStyle: "none",
  p: 0.5,
  m: 0.5,
}

export const EditUserModal = ({ shouldOpen, userInformation, userRoles, userPrograms, handleConfirmClose, handleUsersUpdate }) => {
  let context = useContext(AppContext)
  const auth = useAuth()
  const [emailChecked, setEmailChecked] = React.useState(userInformation.emailVerified)
  const [isDisabled, setIsDisabled] = useState(false)

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }))

  // ROLE VARIABLES
  const [roleChipTouched, setRoleChipTouched] = useState(false)
  const [selectedUserRoleChips, setSelectedUserRoleChips] = useState(
    context.clientRoleData.map((option) => {
      return { ...option, selected: false }
    })
  )

  // PROGRAM VARIABLES
  const [allChipsSelected, setAllChipsSelected] = useState()
  const [programChipTouched, setProgramChipTouched] = useState(false)
  const [selectedUserProgramChips, setSelectedUserProgramChips] = useState(
    context.clientGroupData.map((option) => {
      return { ...option, selected: false }
    })
  )

  useEffect(() => {
    setUserRoleChips()
  }, [userRoles])

  useEffect(() => {
    setUserProgramChips()
  }, [userPrograms])

  // EMAIL
  const handleSaveClick = async () => {
    let body = {
      ...userInformation,
      emailVerified: emailChecked,
    }

    try {
      await apiCalls.putKeycloakUser(userInformation.id, body)
      handleUsersUpdate()
      notify("success", `Changes have been successfully applied to ${userInformation.username}.`)
    } catch (error) {
      console.error("error", error)
      if (error.response?.status === 401) {
        auth.signinRedirect()
      } else {
        notify("error", "The changes could not be made to the user. Please try again.")
      }
    }

    handleConfirmClose()
  }

  const handleEmailVerifiedChange = (event) => {
    setEmailChecked(event.target.checked)
  }

  // CHIPS
  const clearRoleChips = () => {
    setSelectedUserRoleChips((prevState) => {
      return prevState.map((option) => {
        return {
          ...option,
          selected: false,
        }
      })
    })
  }

  const clearProgramChips = () => {
    setSelectedUserProgramChips((prevState) => {
      return prevState.map((option) => {
        return {
          ...option,
          selected: false,
        }
      })
    })
  }

  const setUserRoleChips = () => {
    clearRoleChips()
    userRoles.forEach((role) => {
      setSelectedUserRoleChips((prevState) => {
        return prevState.map((option) => {
          if (option.name === role.name) {
            return {
              ...option,
              selected: true,
            }
          }
          return option
        })
      })
    })
  }

  const setUserProgramChips = () => {
    clearProgramChips()
    userPrograms.forEach((program) => {
      setSelectedUserProgramChips((prevState) => {
        return prevState.map((option) => {
          if (option.name === program.name) {
            return {
              ...option,
              selected: true,
            }
          }
          return option
        })
      })
    })
  }

  useEffect(() => {
    setIsDisabled(true)

    if (roleChipTouched) {
      let rolesAdded = selectedUserRoleChips.filter((item) => {
        if (item.selected && !userRoles.includes(item.name)) {
          return item
        }
      })

      let rolesDeleted = selectedUserRoleChips.filter((item) => {
        if (!item.selected) {
          return item
        }
      })

      rolesAdded.forEach((item) => {
        handleSubmitChips("add", "role", item)
      })

      rolesDeleted.forEach((item) => {
        handleSubmitChips("delete", "role", item)
      })
    }

    setIsDisabled(false)
  }, [selectedUserRoleChips])

  useEffect(() => {
    setIsDisabled(true)

    // NOTE: Renders to either 'Select All' or 'Unselect All' based on if the user is in all of the programs.
    setAllChipsSelected(selectedUserProgramChips.some((item) => item.selected === false) ? false : true)

    if (programChipTouched) {
      let programsAdded = selectedUserProgramChips.filter((item) => {
        if (item.selected && !userPrograms.includes(item.name)) {
          return item
        }
      })

      let programsDeleted = selectedUserProgramChips.filter((item) => {
        if (!item.selected) {
          return item
        }
      })

      programsAdded.forEach((item) => {
        handleSubmitChips("add", "program", item)
      })

      programsDeleted.forEach((item) => {
        handleSubmitChips("delete", "program", item)
      })
    }

    setIsDisabled(false)
  }, [selectedUserProgramChips])

  const handleRoleChipClick = (option) => {
    setRoleChipTouched(true)
    setSelectedUserRoleChips((prevState) => {
      return prevState.map((item) => {
        if (item.name === option.name) {
          return {
            ...item,
            selected: !item.selected,
          }
        }
        return item
      })
    })
  }

  const handleProgramChipClick = (option, isSelectAll) => {
    setProgramChipTouched(true)
    setSelectedUserProgramChips((prevState) => {
      // NOTE: Either assigns/unassigns all programs.
      if (isSelectAll) {
        return prevState.map((item) => {
          return {
            ...item,
            selected: option,
          }
        })
      } else {
        return prevState.map((item) => {
          if (item.name === option.name) {
            return {
              ...item,
              selected: !item.selected,
            }
          }
          return item
        })
      }
    })
  }

  const handleSubmitChips = async (mode, type, entity) => {
    if (type === "role") {
      let body = [
        {
          id: entity.id,
          name: entity.name,
          description: entity.description,
        },
      ]

      if (mode === "add") {
        let response = await apiCalls.postRolesToUser(userInformation.id, body)
        console.log("Added role:", response)
      } else if (mode === "delete") {
        let response = await apiCalls.deleteRolesFromUser(userInformation.id, body)
        console.log("Deleted role:", response)
      }
    } else {
      if (mode === "add") {
        let response = await apiCalls.setUserToProgram(userInformation.id, entity.id)
        console.log("Added program:", response)
      } else if (mode === "delete") {
        let response = await apiCalls.removeUserFromProgram(userInformation.id, entity.id)
        console.log("Deleted program:", response)
      }
    }
  }

  return (
    <Dialog
      open={shouldOpen}
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="edit-user-dialog-title"
        fontFamily="Merriweather"
      >
        User Information
      </DialogTitle>
      <IconButton
        aria-label="close-dialog"
        onClick={() => handleConfirmClose()}
        sx={dialogStyle}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid
          container
          spacing={2}
          columns={11}
        >
          {/* USERNAME, FIRST NAME, LAST NAME FIELDS */}
          <Grid
            item
            xs={5.5}
          >
            <Grid
              container
              spacing={1.35}
              columns={5}
              sx={{ paddingLeft: 1.35 }}
            >
              <Grid
                item
                xs={5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="username"
                    id="username"
                    label="Username"
                    type="text"
                    value={userInformation.username}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="firstName"
                    id="first-name"
                    label="First Name"
                    type="text"
                    value={userInformation.firstName}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="lastName"
                    id="last-name"
                    label="Last Name"
                    type="text"
                    value={userInformation.lastName}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* EMAIL, EMAIL VERIFIED */}
          <Grid
            item
            xs={5.5}
          >
            <Grid
              container
              spacing={1.35}
              columns={5}
            >
              <Grid
                item
                xs={5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="email"
                    id="email"
                    label="Email"
                    type="text"
                    value={userInformation.email}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl
                  component="fieldset"
                  variant="standard"
                  sx={{ display: "flex", alignItems: "flex-start" }}
                >
                  <FormGroup>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                    >
                      <Typography>Email verified:</Typography>
                      <FormControlLabel
                        checked={emailChecked}
                        onChange={handleEmailVerifiedChange}
                        control={<Switch color="primary" />}
                        label={emailChecked ? "Yes" : "No"}
                        labelPlacement="end"
                      />
                    </Stack>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* MIDDLE DIVIDER */}
          <Grid
            item
            xs={11}
            sx={{ paddingTop: "20px !important" }}
          >
            <Divider sx={{ fontFamily: "Merriweather" }}>Assignments</Divider>
          </Grid>

          {/* ROLES */}
          <Grid
            item
            xs={5.5}
          >
            <Divider sx={{ fontFamily: "Merriweather" }}>Roles</Divider>
            <FormControl sx={{ minWidth: "100%" }}>
              <Paper
                sx={chipStyle}
                component="ul"
              >
                {selectedUserRoleChips.map((option) => (
                  <ListItem key={option.id}>
                    <Chip
                      label={option.name}
                      color={option.selected ? "primary" : "default"}
                      variant={option.selected ? "filled" : "outlined"}
                      onClick={() => handleRoleChipClick(option)}
                    />
                  </ListItem>
                ))}
              </Paper>
            </FormControl>
          </Grid>

          {/* PROGRAMS */}
          <Grid
            item
            xs={5.5}
          >
            <Divider sx={{ fontFamily: "Merriweather" }}>Programs</Divider>
            <FormControl sx={{ minWidth: "100%" }}>
              <Paper
                sx={chipStyle}
                component="ul"
              >
                <div style={{ width: "100%", justifyContent: "center", marginTop: "5px", marginBottom: "5px", fontWeight: "600" }}>
                  <Chip
                    label={allChipsSelected ? "UNSELECT ALL" : "SELECT ALL"}
                    icon={allChipsSelected ? <RemoveDoneIcon /> : <DoneAllIcon />}
                    color={allChipsSelected ? "primary" : "default"}
                    variant={allChipsSelected ? "filled" : "outlined"}
                    onClick={() => handleProgramChipClick(!allChipsSelected, true)}
                    sx={{ fontWeight: "bold" }}
                  />
                </div>

                {selectedUserProgramChips.map((option) => (
                  <ListItem key={option.id}>
                    <Chip
                      label={option.name}
                      color={option.selected ? "primary" : "default"}
                      variant={option.selected ? "filled" : "outlined"}
                      onClick={() => handleProgramChipClick(option, false)}
                    />
                  </ListItem>
                ))}
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={isDisabled}
          onClick={() => handleSaveClick()}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

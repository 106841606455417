import { React, useContext, useEffect, useState } from "react"

// ** Toastify
import { ToastContainer } from "react-toastify"

// ** MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import RestoreIcon from "@mui/icons-material/Restore"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Custom
import Header from "../layout/Header"
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import { dialogPaperStyle, dialogStyle, getProgramName, getWarehouseBuildingNumber, notify } from "../utils"

const DeletedInventoryView = () => {
  let context = useContext(AppContext)
  const title = "Deleted Items"
  const [isLoading, setIsLoading] = useState(true)
  const [deletedItems, setDeletedItems] = useState([])
  const [selectedItem, setSelectedItem] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    fetchDeletedItems()
  }, [])

  const fetchDeletedItems = async () => {
    try {
      let deletedData = await apiCalls.getRecords(`InventoryRecords?%24filter=notDeletedState%20eq%20false`)
      setDeletedItems(deletedData.data.value)
      setIsLoading(false)
    } catch (error) {
      console.log("ERROR: ", error)
      notify("error", "There was a problem loading the deleted items.")
    }
  }

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filerable: false,
      hideable: false,
      disableExport: false,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip
              title="Delete"
              arrow
              placement="right"
            >
              <IconButton
                color="error"
                onClick={() => {
                  setIsDialogOpen(true)
                  setSelectedItem({ row: params.row, action: "DELETE" })
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Restore"
              arrow
              placement="right"
            >
              <IconButton
                color="primary"
                onClick={() => {
                  setIsDialogOpen(true)
                  setSelectedItem({ row: params.row, action: "RESTORE" })
                }}
              >
                <RestoreIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
      },
    },
    {
      field: "program",
      headerName: "Program",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return getProgramName(params.row.managementRecordArmyProgramId, context.allProgramsData)
      },
    },
    { field: "nationalStockNumber", headerName: "NSN", minWidth: 130, align: "left", headerAlign: "left" },
    { field: "partNumber", headerName: "Part #", minWidth: 120, align: "left", headerAlign: "left" },
    { field: "manp", headerName: "MANP", minWidth: 110 },
    { field: "materialControlNumber", headerName: "MCN", minWidth: 150 },
    { field: "serialNumber", headerName: "Serial #", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "nomenclature", headerName: "Nomenclature", minWidth: 250, align: "left", headerAlign: "left" },
    { field: "nationalItemIdentificationNumber", headerName: "NIIN", minWidth: 100, align: "left", headerAlign: "left" },
    { field: "quantity", headerName: "Qty", type: "number", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "unitOfIssue", headerName: "UI", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "propertyNumber", headerName: "Property #", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "radioFrequencyIdentificationNumber", headerName: "RFID", minWidth: 230, align: "left", headerAlign: "left" },
    {
      field: "conditionCode",
      headerName: "CC",
      minWidth: 50,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "hazardousMaterielIndicatorCode",
      headerName: "HMIC",
      minWidth: 50,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      type: "number",
      minWidth: 80,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      type: "number",
      minWidth: 120,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
      align: "left",
      headerAlign: "left",
    },
    { field: "location", headerName: "Location", minWidth: 80, align: "left", headerAlign: "left" },
    {
      field: "site",
      headerName: "Site",
      minWidth: 80,
      align: "left",
      headerAlign: "left",
      valueGetter: (params) => {
        return getWarehouseBuildingNumber(params.row.managementRecordWarehouseId, context.warehouseData)
      },
    },
    { field: "unitOfMeasurement", headerName: "UOM", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "federalSupplyClassification", headerName: "FSC", minWidth: 25, align: "left", headerAlign: "left" },
    { field: "uniqueItemIdentifier", headerName: "UII", minWidth: 30, align: "left", headerAlign: "left" },
    { field: "commercialAndGovernmentEntityCode", headerName: "CAGE Code", minWidth: 80, align: "left", headerAlign: "left" },
    {
      field: "armyEnterpriseSystemIntegrationProgramSerializationIndicator",
      headerName: "AESIP Serialization Indicator",
      minWidth: 100,
      align: "left",
      headerAlign: "left",
    },
    { field: "assetIdentification", headerName: "Asset ID", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "sourceOfSupply", headerName: "SOS", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "automaticReturnItemList", headerName: "ARIL", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "accountingRequirementsCode", headerName: "ARC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "controlledInventoryItemCode", headerName: "CIIC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "criticalityCode", headerName: "CRITL Code", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "demilitarizationCode", headerName: "DEMIL Code", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "recoverabilityCode", headerName: "Recoverability Code", minWidth: 160, align: "left", headerAlign: "left" },
    { field: "specialControlItemCode", headerName: "SCIC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "supplyCategoriesOfMaterielCode", headerName: "SCMC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "shelfLifeCode", headerName: "SLC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "specialRequirementsCode", headerName: "SRC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "materielCategoryStructureFourAndFive", headerName: "MATCAT 4 & 5", minWidth: 140, align: "left", headerAlign: "left" },
    {
      field: "exchangePrice",
      headerName: "Exchange Price",
      minWidth: 140,
      align: "left",
      headerAlign: "left",
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
    },
    { field: "typeOfStorageArmy", headerName: "TOS ARMY", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "hazardousCharacteristicsCode", headerName: "HCC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "controlledInventoryItemCodeAmdf", headerName: "CIIC AMDF", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "methodOfPreservationArmy", headerName: "ARMY MOP", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "electrostaticDischargeCode", headerName: "ESD", minWidth: 80, align: "left", headerAlign: "left" },
  ]

  const handleDialog = (value) => {
    setIsDialogOpen(value)
    setSelectedItem("")
  }

  const handleAction = async () => {
    try {
      if (selectedItem.action === "DELETE") await apiCalls.deleteRecord(selectedItem.row.id, "InventoryRecords")
      else await apiCalls.patchRecord(selectedItem.row.id, "InventoryRecords", "NotDeletedState", "replace", "true")
      fetchDeletedItems()
      notify("success", `Item was ${selectedItem.action == "DELETE" ? "deleted" : "restored"} successfully.`)
    } catch (error) {
      console.log("ERROR: ", error)
      notify("error", `There was a problem ${selectedItem.action === "DELETE" ? "deleting" : "restoring"} the item.`)
    }

    handleDialog(false)
  }

  const showItemInfoText = () => {
    return (
      <>
        <p>
          <b>Nomenclature: </b>
          {selectedItem.row.nomenclature}
        </p>
        <p>
          <b>Property Number: </b>
          {selectedItem.row.propertyNumber}
        </p>
      </>
    )
  }

  const showDialog = () => {
    return (
      <Dialog
        open={isDialogOpen}
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: dialogPaperStyle }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="delete-inventory-dialog-title"
          fontFamily="Merriweather"
        >
          {selectedItem.action === "DELETE" ? "Confirm Delete" : "Confirm Restore"}
        </DialogTitle>
        <IconButton
          aria-label="close-dialog"
          onClick={() => handleDialog(false)}
          sx={dialogStyle}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            {selectedItem.action === "DELETE"
              ? "Deleting the following item will remove it from the database forever, meaning it can not be recovered to be added back into inventory:"
              : "Restoring the following item will add it back to the program's inventory it was last in:"}
          </Typography>
          {showItemInfoText()}
          <Typography gutterBottom>Are you sure you would like to {selectedItem.action === "DELETE" ? "delete" : "restore"} this item?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color={selectedItem.action === "DELETE" ? "error" : "primary"}
            onClick={() => handleAction()}
          >
            {selectedItem.action === "DELETE" ? "Delete" : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isDialogOpen && showDialog()}
      <Header title={title} />
      <div style={{ height: "75vh", width: "100%" }}>
        <CustomStripedGrid
          loading={isLoading}
          data={deletedItems}
          columns={columns}
        />
      </div>
    </>
  )
}

export default DeletedInventoryView

import { useEffect, useState, useContext } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"

// ** Toastify
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"

// ** Custom
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import LoadingBackdrop, {
  getProgramName,
  getWarehouseBuildingNumber,
  propertyFormatter,
  notify,
  timeRegexValidation,
  formatUTCDate,
  getFormattedDate,
} from "../utils"
import { numberInUOM, transactionTypes } from "../context/variables"
import Header from "../layout/Header"

const ItemTransactionsView = ({ leftMenuDrawerOpen, viewTitle }) => {
  const context = useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = params
  const inventoryItem = location?.state.params
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState("")

  useEffect(() => {
    fetchData()
  }, [viewTitle])

  useEffect(() => {
    if (data) {
      setLoaded(true)
    }
  }, [data])

  const fetchData = async () => {
    try {
      let transactionsData =
        viewTitle === "Item Transactions"
          ? await apiCalls.getRecords(`RecordTransactions?%24filter=InventoryRecordId%20eq%20${id}`)
          : await apiCalls.getRecords(`RecordTransactions`)
      setData(transactionsData.data.value)
    } catch (error) {
      console.error("Error: ", error)
      notify("error", `There was a problem loading the ${viewTitle === "Item Transactions" ? "item's" : "inventory"} transactions.`)
    }
  }

  const valueReformatter = (value) => {
    // Reformat GUID Program/Warehouse values to their corresponding names.
    let pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    if (value.match(pattern)) {
      let programName = getProgramName(value, context.allProgramsData)
      let warehouseBuildingNumber = getWarehouseBuildingNumber(value, context.warehouseData)
      return programName != null ? programName : warehouseBuildingNumber
    }

    // Reformat UOMs that have a number in them.
    if (value in numberInUOM) {
      return numberInUOM[value]
    }

    return value
  }

  const columns = [
    {
      field: "dateTime",
      headerName: "Date/Time",
      width: 170,
      valueFormatter: (params) => formatUTCDate(params?.value, true),
    },
    ...(viewTitle === "Inventory Transactions" ? [{ field: "programName", headerName: "Program", width: 100 }] : []),
    {
      field: "transactionType",
      headerName: "Type",
      width: 100,
      type: "singleSelect",
      valueOptions: transactionTypes,
    },
    {
      field: "property",
      headerName: "Property",
      width: 250,
      valueFormatter: (params) => propertyFormatter(params),
    },
    {
      field: "previousValue",
      headerName: "Previous Value",
      width: viewTitle === "Item Transactions" ? 370 : 320,
      valueFormatter: (params) => {
        if (timeRegexValidation(params.value)) {
          return getFormattedDate(params?.value)
        } else {
          return valueReformatter(params.value)
        }
      },
    },
    {
      field: "newValue",
      headerName: "New Value",
      width: viewTitle === "Item Transactions" ? 370 : 320,
      valueFormatter: (params) => {
        if (timeRegexValidation(params.value)) {
          return getFormattedDate(params?.value)
        } else {
          return valueReformatter(params.value)
        }
      },
    },
    {
      field: "explanation",
      headerName: "Explanation",
      flex: 1,
      minWidth: 260,
    },
    { field: "username", headerName: "Made By", flex: 1 },
  ]

  const tableRows = () => {
    let rows = data.map((item) => {
      return {
        id: item.id,
        transactionType: item.transactionType,
        dateTime: item.transactionDate,
        property: item.property,
        previousValue: item.previousValue,
        newValue: item.newValue,
        explanation: item.explanation,
        username: item.username,
        programName: item.programName,
      }
    })

    /*
     * NOTE: Include a generic ADD transaction if the inventory record doesn't have an existing ADD/RECD transaction for it.
     * This is mostly to resolve the issue of adding the ADD/RECD transactions if data is uploaded via CSV. Only do this
     * when showing a single item's transactions.
     */
    if (viewTitle === "Item Transactions" && !rows.some((item) => item.transactionType == "ADD" || item.transactionType == "RECD")) {
      let addTransaction = {
        id: inventoryItem.id, // Using the IR's ID is sufficient so the rows IDs are unique.
        transactionType: "ADD",
        dateTime: inventoryItem.createdDate,
        property: "",
        previousValue: "",
        newValue: "",
        explanation: "Item was added into AWIMS via CSV/other.",
        username: "N/A",
      }
      rows.push(addTransaction)
    }

    return rows
  }

  if (loaded) {
    return (
      <>
        <div
          className="back-btn-wrapper"
          style={{ marginBottom: 20 }}
        >
          <IconButton
            onClick={() => navigate(-1)}
            size="large"
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </div>
        <Header title={viewTitle} />
        <div style={{ height: "75vh", width: "100%" }}>
          <CustomStripedGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "dateTime", sort: "desc" }],
              },
            }}
            data={tableRows()}
            columns={columns}
            title={viewTitle}
            autoHeight
          />
        </div>
      </>
    )
  } else return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
}

export default ItemTransactionsView
